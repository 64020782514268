import { v4 as uuidv4 } from 'uuid'
import { styled, useTheme } from '@mui/material/styles'
import { Box, darken, Divider, lighten, Paper, Popper, Stack, Typography } from '@mui/material'
import React, { MouseEvent, useMemo, useState } from 'react'
import { VirtualElement } from '@popperjs/core'
import { format } from 'date-fns'
import { fNumber } from '../../../utils/formatNumber'

const TOOLTIP_DATE_FORMAT = 'MMM dd \'\'yy hh:00 a'
const POPOVER_BG_COEF = .15

export type InputPoint = {
  value: number,
  startDate?: Date,
  endDate?: Date
}

type SparkbarChartProps = {
  data: InputPoint[],
  width?: number,
  height?: number,
  barWidth?: number,
  color?: string
}

type DataPoint = {
  uuid: string,
  value: number,
  startDate?: Date,
  endDate?: Date,

  // chart positioning stuff
  xLeft: number,
  yTop: number,
  barHeight: number
}

export function SparkbarChart({ data, width = 240, height = 80, barWidth = 5, color = '' }: SparkbarChartProps) {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const [anchorPosition, setAnchorPosition] = useState<VirtualElement | undefined>(undefined)
  const [activePoint, setActivePoint] = useState<DataPoint | null>(null)

  // generate points
  const points = useMemo(() => toPoints(data, width, height), [data, width, height])
  const max = useMemo(() => getMax(points), [points])

  // handlers
  const handleMouseEnter = (e: MouseEvent<SVGElement>) => setOpen(true)
  const handleMouseLeave = (e: MouseEvent<SVGElement>) => {
    setOpen(false)
    setAnchorPosition(undefined)
  }
  const handleMouseMove = (e: MouseEvent<SVGElement>) => {
    // update the position
    // @ts-ignore
    setAnchorPosition({ getBoundingClientRect: generateGetBoundingClientRect(e.clientX, e.clientY) })
  }
  const handleBarEnter = (p: DataPoint, e: MouseEvent<SVGRectElement>) => setActivePoint(p)

  color = color || theme.palette.chart.green[0]

  const id = open ? 'sparkChartPopper' : undefined
  const cascadedOpen = open && Boolean(anchorPosition) && Boolean(activePoint)
  const fNullableDate = (d: Date | undefined) => d ? format(d, TOOLTIP_DATE_FORMAT) : null

  return (
    <SparkLineWrapper>
      <svg
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        viewBox={`0 0 ${width} ${height}`}
        style={{overflow: "visible"}}
      >
        <g>
          {points.map(p => {
            return (
              <Bar
                key={p.uuid}
                point={p}
                barWidth={barWidth}
                color={color}
                onBarEnter={handleBarEnter}
              />
            )
          })}
        </g>
        {
          points.length && max != 0 && (
            <g style={{pointerEvents: "none"}}>
              <line
                x1={0}
                y1={0}
                x2={width}
                y2={0}
                style={{ stroke: "#CCC", strokeOpacity: .25, strokeDasharray: "4, 3", strokeWidth: 1 }}
              />
              <text fill="#CCC" opacity={.35} style={{fontSize: 12}}  y={4} x={-6} textAnchor="end">
                {fNumber(max)}
              </text>
            </g>
          )
        }

      </svg>

      <Popper
        id={id}
        open={cascadedOpen}
        anchorEl={anchorPosition}
        disablePortal={true}
        style={{ zIndex: 10 }}
      >
        <Paper elevation={1} sx={{minWidth: 125, background: lighten(theme.palette.background.paper, POPOVER_BG_COEF)}}>
          <Arrow  />
          <Stack direction='row' alignItems='center' justifyContent='space-between' py={1.5} px={2}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <LegendCircle sx={{background: color}} />
              <Typography variant='small'>Events</Typography>
            </Stack>
            <Typography variant='smallHighlight'>{activePoint?.value}</Typography>
          </Stack>
          <Divider />
          <Stack direction="row" spacing={2} py={1.5} px={2}>
            <Typography variant="small">{fNullableDate(activePoint?.startDate)}</Typography>
            <Typography variant="small">-</Typography>
            <Typography variant="small">{fNullableDate(activePoint?.endDate)}</Typography>
          </Stack>
        </Paper>
      </Popper>
    </SparkLineWrapper>
  )
}

type BarProps = {
  point: DataPoint,
  barWidth: number,
  color: string,
  onBarEnter: (p: DataPoint, e: MouseEvent<SVGRectElement>) => void
}

function Bar({ point, barWidth, color, onBarEnter }: BarProps) {
  return (
    <>
      <rect
        x={point.xLeft}
        y={point.yTop}
        width={barWidth}
        height={point.barHeight}
        style={{ fill: color }}
        onMouseEnter={(e: MouseEvent<SVGRectElement>) => onBarEnter(point, e)}
      />
      <rect
        x={point.xLeft}
        y={0}
        width={barWidth}
        height={point.yTop}
        style={{ fill: 'transparent' }}
        onMouseEnter={(e: MouseEvent<SVGRectElement>) => onBarEnter(point, e)}
      />
    </>
  )
}

function toPoints(data: InputPoint[], width: number, height: number) {
  const min = 0
  const max = Math.max.apply(Math, data.map(it => it.value))
  const yScale = height / Math.max(max - min, 1)
  const xScale = width / data.length
  return data.map((it, idx) => {
    const hasNoHeight = it.value == min
    const barHeight = hasNoHeight ? 1 : it.value * yScale
    const y = height - barHeight
    const x = idx * xScale
    return {
      uuid: uuidv4(),
      xLeft: x,
      yTop: y,
      barHeight: barHeight,
      value: it.value,
      startDate: it.startDate,
      endDate: it.endDate
    }
  })
}

function getMax(points: DataPoint[]){
  return points.reduce((memo, it) => {
    return Math.max(memo, it.value)
  }, 0)
}

export const SparkLineWrapper = styled(Box)(({ theme }) => {
  return {
    '[role="tooltip"]': {
      visibility: "hidden",
      pointerEvents: "none"
    },
    ':hover [role="tooltip"]': {
      visibility: "visible"
    }
  }
})

export const LegendCircle = styled(Box)(({ theme }) => {
  return {
    width: 12,
    height: 12,
    borderRadius: '50%'
  }
})

export const Arrow = styled(Box)(({ theme }) => {
  const color = lighten(theme.palette.background.paper, POPOVER_BG_COEF)
  return {
    position: "absolute",
    width: 0,
    height: 0,
    borderWidth: 10,
    borderStyle: "solid",
    borderColor: `${color} transparent transparent transparent`,
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 100%)",
  }
})


function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y - 100,
    right: x,
    bottom: y,
    left: x,
  });
}