import { Account } from '../../../@types/account'
import { OauthProvider } from '../../../@types/oauth'
import { GoogleDriveIntegrationDescription } from './descriptions/GoogleDriveIntegrationDescription'

type IntegrationDescriptionProps = {
  account: Account
}

// eventually we might want to move this to a consolidated endpoint instead of writing frontend code for each
// in the short term, our number of integrations will be small, so this is fine
export function IntegrationDescription({account} : IntegrationDescriptionProps){
  if(account.provider == OauthProvider.GOOGLE_DRIVE){
    return <GoogleDriveIntegrationDescription account={account} />
  }
  return <></>
}