// @mui
import { styled } from "@mui/material/styles";
import { Box, Button, Card, Container, Divider, Link, Paper, Stack, Typography } from "@mui/material";
// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../../components/Page";
// sections
import LoginForm from "./LoginForm";
import * as React from "react";
import LogoFull from "../../components/LogoFull";
import { motion } from "framer-motion";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  },
  background: theme.palette.background.default
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 425,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {

  return (
    <Page title="Login">
      <RootStyle>

        <Container maxWidth="sm">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.35 }}
          >
            <ContentStyle>
              <Paper elevation={4} sx={{ p: 4 }}>
                <LogoFull sx={{ mb: 4, px: 8 }} />

                <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h3" gutterBottom align="center">
                      Sign in
                    </Typography>
                  </Box>

                </Stack>

                <LoginForm />

                <Divider sx={{ my: 3 }}>or</Divider>

                <Box>
                  <Button
                    component={Link}
                    href="/api/v1/login/oauth2/authorize/google"
                    fullWidth
                    variant="outlined"
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Box
                        sx={{ width: 16 }}
                        component="img"
                        src="/assets/icons/logos/google_sso.svg"
                      />
                      <Box>Sign in with Google</Box>
                    </Stack>
                  </Button>
                </Box>
                {/*{!smUp && (*/}
                {/*    <Typography variant="body2" align="center" sx={{ mt: 3 }}>*/}
                {/*        Don’t have an account?{' '}*/}
                {/*        <Link variant="subtitle2" component={RouterLink} to={"foo"}>*/}
                {/*            Get started*/}
                {/*        </Link>*/}
                {/*    </Typography>*/}
                {/*)}*/}
              </Paper>

            </ContentStyle>
          </motion.div>
        </Container>
      </RootStyle>
    </Page>
  );
}
