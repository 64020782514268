import { Asset } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { ViewDocumentSummaryPopover } from './ViewDocumentSummaryPopover'

export function DocumentSummaryReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  return (
    <ReadinessContainer
      icon='eva:bulb-outline'
      state={state}
      title='Asset Summary'
      subtitle='Summarize assets to help users understand content at a glance. This also aids in helping individuals to search by topics!'
    >
      {isReady && (<ViewDocumentSummaryPopover asset={asset} />)}
    </ReadinessContainer>
  )
}