import {
  BootstrapEvent,
  DownloadEvent,
  ShareEvent,
  TimelineEvent,
  TimelineEventType,
  ViewEvent,
} from '../../../../@types/analytics/timeline-events'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { Box, Button, Stack, Typography } from '@mui/material'
import { fDateTime } from '../../../../utils/formatTime'
import { Link } from 'react-router-dom'
import React from 'react'

export function UserTimelineEvent({ event }: { event: TimelineEvent }) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <UserTimelineEventContent event={event} />
      </TimelineContent>
    </TimelineItem>
  )
}

function UserTimelineEventContent({ event }: { event: TimelineEvent }) {
  if (event.type == TimelineEventType.BOOTSTRAP) {
    return <BootstrapEventCmp event={event as BootstrapEvent} />
  }

  if (event.type == TimelineEventType.DOWNLOAD) {
    return <DownloadEventCmp event={event as DownloadEvent} />
  }

  if (event.type == TimelineEventType.SHARE) {
    return <ShareEventCmp event={event as ShareEvent} />
  }

  if (event.type == TimelineEventType.VIEW) {
    return <ViewEventCmp event={event as ViewEvent} />
  }

  return null
}

function BootstrapEventCmp({ event }: { event: BootstrapEvent }) {
  return (
    <Stack direction='column'>
      <Typography variant='small' color='text.deemphasized'>{fDateTime(event.createdAt)}</Typography>
      <Typography variant='h6'>Logged in to Masset</Typography>
    </Stack>
  )
}

function DownloadEventCmp({ event }: { event: DownloadEvent }) {
  return (
    <Stack direction='column'>
      <Typography variant='small' color='text.deemphasized'>{fDateTime(event.createdAt)}</Typography>
      <Typography variant='h6'>Downloaded {event.data.assetName}</Typography>
      <Box mt={1}>
        <Button
          component={Link}
          to={`/assets/detail/${event.data.assetId}`}
          variant='outlined'
          size='small'
          fullWidth={false}
        >
          Go to Asset
        </Button>
      </Box>
    </Stack>
  )
}

function ShareEventCmp({ event }: { event: ShareEvent }) {
  return (
    <Stack direction='column'>
      <Typography variant='small' color='text.deemphasized'>{fDateTime(event.createdAt)}</Typography>
      <Typography variant='h6'>Shared {event.data.assetName}</Typography>
      <Stack mt={1} spacing={2} direction='row'>
        <Box mt={1}>
          <Button
            component={Link}
            to={`/assets/detail/${event.data.assetId}`}
            variant='outlined'
            size='small'
            fullWidth={false}
          >
            Go to Asset
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}


function ViewEventCmp({ event }: { event: ViewEvent }) {
  return (
    <Stack direction='column'>
      <Typography variant='small' color='text.deemphasized'>{fDateTime(event.createdAt)}</Typography>
      <Typography variant='h6'>Viewed {event.data.assetName}</Typography>
      <Box mt={1}>
        <Button
          component={Link}
          to={`/assets/detail/${event.data.assetId}`}
          variant='outlined'
          size='small'
          fullWidth={false}
        >
          Go to Asset
        </Button>
      </Box>
    </Stack>
  )
}


function NewAssetRequestCmp({ event }: { event: ViewEvent }) {
  return (
    <Stack direction='column'>
      <Typography variant='small' color='text.deemphasized'>{fDateTime(event.createdAt)}</Typography>
      <Typography variant='h6'>Requested New Asset</Typography>
      <Box mt={1}>
        <Button variant='outlined' size='small' fullWidth={false}>View Request</Button>
      </Box>
    </Stack>
  )
}