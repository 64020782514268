import { alpha, useTheme } from '@mui/material/styles'
import { Avatar, Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import Iconify from '../../../Iconify'
import React, { useMemo, useState } from 'react'
import { HoverableItem } from './HoverableItem'
import { AVATAR_SIZE, ICON_SIZE, InternalFormGroupUser } from './GroupMembersField'
import { useFormContext, useWatch } from 'react-hook-form'
import { UserSearchSelect } from '../../../user-select/UserSearchSelect'
import { User } from '../../../../@types/user'
import orderBy from 'lodash/orderBy'
import { internalGroupUserForUser } from './group-member-field-util'

export function AddPeopleItem({ fieldName }: { fieldName: string }) {
  // hooks
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const [intermediateUsers, setIntermediateUsers] = useState<User[]>([])
  const selectedUsers = useWatch({ name: fieldName }) as InternalFormGroupUser[]
  const { setValue } = useFormContext()

  // handlers
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleUserSelectChange = (users: User[]) => {
    setIntermediateUsers(users)
  }
  const handleAddPeople = () => {
    const toAdd = intermediateUsers.map(it => internalGroupUserForUser(it))
    const newValue = [...selectedUsers, ...toAdd]
    const sorted = orderBy(newValue, 'name')
    setValue(fieldName, sorted, { shouldDirty: true })
    handleClose()
  }

  // calculated props
  const selectedUserIds = useMemo(() => selectedUsers.map(it => it.userId), [selectedUsers])
  return (
    <>
      <HoverableItem onClick={handleOpen} sx={{ cursor: 'pointer' }}>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Avatar sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: alpha(theme.palette.info.dark, .35) }}>
            <Iconify icon='eva:person-add-outline' sx={{ height: ICON_SIZE, width: ICON_SIZE }} />
          </Avatar>
          <Typography>Add people</Typography>
        </Stack>
      </HoverableItem>

      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth={true}
        onClose={handleClose}
      >

        <Box p={7}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Typography variant='h2' mb={4}>
            Add people
          </Typography>

          <UserSearchSelect ignoredUserIds={selectedUserIds} onChange={handleUserSelectChange} />

          <Stack spacing={2} mt={4}>
            <Button
              fullWidth={true}
              variant='contained'
              color='primary'
              size='large'
              onClick={handleAddPeople}
              disabled={intermediateUsers.length === 0}
            >
              Add Users
            </Button>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}