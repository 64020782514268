import { Asset } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { ViewTranscriptPopover } from './ViewTranscriptPopover'

export function TranscriptionReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  return (
    <ReadinessContainer
      icon="ph:microphone"
      state={state}
      title="Transcription"
      subtitle="Has Masset generated a transcription for this asset?"
    >
      { isReady && (<ViewTranscriptPopover asset={asset} />)}
    </ReadinessContainer>
  )
}