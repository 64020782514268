import { SharingFilter } from '../../../@types/analytics/analytics-asset'
import { Box, Button, Divider, Popover, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AdminAnalyticsContext, LocalCustomFilter } from '../../../contexts/AdminAnalyticsContext'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { FormProvider, RHFSelect } from '../../hook-form'
import { GeneralAccessType } from '../../../@types/sharing'
import { ACCESS_TYPE_TYPE_EN } from '../../../utils/appConstants'

export function SharingCustomFilter({ filter }: { filter: LocalCustomFilter }) {
  const { updateCustomSharingFilter, removeCustomFilter } = useContext(AdminAnalyticsContext)

  // state
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)

  // handlers
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleCancel = () => {
    handleClose()
    if (filter.isDraft) {
      removeCustomFilter(filter.id)
    }
  }
  const handleApply = (data: FormValues) => {
    handleClose()
    updateCustomSharingFilter(filter.id, data.sharingType)
  }

  // effects
  useEffect(() => {
    if (ref.current && filter.isDraft) {
      setAnchorEl(ref.current)
    }
  }, [ref.current, filter.isDraft])

  // calculated props
  if (!filter.sharingFilter) return <></>
  const accessTypeText = ACCESS_TYPE_TYPE_EN[filter.sharingFilter.sharingType] || ''
  const open = Boolean(anchorEl)
  const id = open ? filter.id : undefined

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        onClick={handleOpen}
        ref={ref}
        sx={{ cursor: 'pointer' }}
      >
        <Typography variant='small'>Sharing:</Typography>
        <Typography variant='small' fontWeight="bold">{accessTypeText}</Typography>
      </Stack>


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -4, horizontal: 0 }}
      >
        <Box p={2} pt={3}>
          <SharingFilterForm
            filter={filter.sharingFilter}
            onCancel={handleCancel}
            onApply={handleApply}
          />
        </Box>


      </Popover>
    </>
  )
}

type FormValues = {
  sharingType: GeneralAccessType
}
type SharingFilterFormProps = {
  filter: SharingFilter,
  onCancel: () => void,
  onApply: (data: FormValues) => void
}

function SharingFilterForm({ filter, onCancel, onApply }: SharingFilterFormProps) {

  // form setup
  const FormSchema = Yup.object().shape({
    sharingType: Yup.mixed<GeneralAccessType>()
      .required()
      .test(value => {
        return Object.values(GeneralAccessType).map(it => it+"").includes(value+"")
      })
  })
  const resolver = yupResolver(FormSchema)
  const defaults = { sharingType: filter.sharingType }
  const methods = useForm<FormValues>({ resolver, defaultValues: defaults, mode: 'onChange' })
  const { handleSubmit, reset, formState: { isValid } } = methods

  return (
    <Box>
      <FormProvider methods={methods}>
        <Stack direction='column' spacing={2}>
          <RHFSelect
            name='sharingType'
            label='Access Type'
            size='small'
          >
            <option value={GeneralAccessType.RESTRICTED}>{ACCESS_TYPE_TYPE_EN[GeneralAccessType.RESTRICTED]}</option>
            <option value={GeneralAccessType.ORGANIZATION}>{ACCESS_TYPE_TYPE_EN[GeneralAccessType.ORGANIZATION]}</option>
          </RHFSelect>

        </Stack>

      </FormProvider>

      <Divider sx={{ my: 2 }} />

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='end'>
        <Button color='inherit' variant='outlined' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          variant='contained'
          size='small'
          color='mint'
          onClick={handleSubmit(onApply)}
        >
          Apply
        </Button>
      </Stack>
    </Box>

  )
}
