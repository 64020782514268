import { Asset } from '../../../@types/asset'
import { Box, Typography } from '@mui/material'
import { useAssetOCRContents } from '../../../hooks/useAssetContent'
import { fDateTime } from '../../../utils/formatTime'
import { useTheme } from '@mui/material/styles'

interface ImageSummaryContentsProps {
  asset: Asset
}

export function OCRContents({ asset }: ImageSummaryContentsProps) {
  const theme = useTheme()
  const { data: ocr } = useAssetOCRContents(asset.assetId)
  return (
    <>
      {ocr && (
        <Box mt={3}
             sx={{
               pl: 2,
               borderLeft: `4px solid ${theme.palette.primary.darker}`,
             }}
        >
          <Typography variant='standardHighlight' component='div'>OCR Extracted Text (Auto-Generated)</Typography>
          <Typography variant='small' component='div' sx={{ mb: 1 }}>
            Generated on {fDateTime(ocr.createdAt)}
          </Typography>
          <Typography variant='small'>{ocr?.contents}</Typography>
        </Box>
      )}
    </>

  )
}