import Page from '../../components/Page'
import { Button, Stack, Typography } from '@mui/material'
import Iconify from '../../components/Iconify'
import { useNavigate } from 'react-router-dom'
import { HierarchyTable } from '../../components/admin/groups/HierarchyTable'
import React, { useState } from 'react'

export function AdminGroups() {
  const navigate = useNavigate()
  const [view, setView] = useState<string>('hierarchy')
  const handleViewChange = (e: React.MouseEvent<HTMLElement>, view: string) => setView(view)
  return (
    <Page title='Groups'>
      <Stack direction='row' justifyContent='space-between' mb={4}>
        <Typography variant='h2'>Group Management</Typography>
        <Button
          variant='contained'
          color='mint'
          size='small'
          startIcon={<Iconify icon={'eva:plus-outline'} />}
          onClick={() => navigate('/admin/groups/new')}
        >
          Add New Group
        </Button>
      </Stack>
      {/*<Stack direction="row" justifyContent="end" my={2}>*/}
      {/*  <ToggleButtonGroup*/}
      {/*    size='small'*/}
      {/*    color='primary'*/}
      {/*    value={view}*/}
      {/*    exclusive*/}
      {/*    onChange={handleViewChange}*/}
      {/*    aria-label='Interval'*/}
      {/*  >*/}
      {/*    <ToggleButton value='flat'>*/}
      {/*      <Stack direction='row' spacing={1} alignItems='center'>*/}
      {/*        <Iconify icon='eva:list-outline' sx={{ width: 18, height: 18 }} />*/}
      {/*        <Typography variant='small'>List</Typography>*/}
      {/*      </Stack>*/}
      {/*    </ToggleButton>*/}
      {/*    <ToggleButton value='hierarchy'>*/}
      {/*      <Stack direction='row' spacing={1} alignItems='center'>*/}
      {/*        <Iconify icon='eva:menu-2-outline'  sx={{ width: 18, height: 18 }} />*/}
      {/*        <Typography variant='small'>Hierarchy</Typography>*/}
      {/*      </Stack>*/}
      {/*    </ToggleButton>*/}
      {/*    /!*<ToggleButton value='model'>*!/*/}
      {/*    /!*  <Stack direction='row' spacing={1} alignItems='center'>*!/*/}
      {/*    /!*    <Iconify icon='eva:grid-outline' sx={{ width: 18, height: 18 }}  />*!/*/}
      {/*    /!*    <Typography variant='small'>Diagram</Typography>*!/*/}
      {/*    /!*  </Stack>*!/*/}
      {/*    /!*</ToggleButton>*!/*/}
      {/*  </ToggleButtonGroup>*/}

      {/*</Stack>*/}

      {/*{view == 'flat' && <FlatTable />}*/}
      {view == 'hierarchy' && <HierarchyTable />}
      {/*{ view == 'model' && <FlatTable/> }*/}
    </Page>
  )
}
