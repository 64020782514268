import api from '@api'
import { BootstrapResponse, LoginResponse } from '../@types/auth'

const DEFAULT_BOOTSTRAP_RESPONSE = {} as BootstrapResponse

export async function getBootstrap(){
  try{
    const response = await api.get<BootstrapResponse>("/api/v1/bootstrap")
    return response.data
  }catch(e){
    return DEFAULT_BOOTSTRAP_RESPONSE
  }
}

export async function login(username: string, password: string) {
  const response = await api.post<LoginResponse>("/api/v1/login", { username, password })
  return response.data
}

export async function logout() {
  const response = await api.post("/api/v1/logout")
  return response.data
}