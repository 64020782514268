import { CreateUserDto, UpdateUserDto, User } from "../@types/user";
import {createContext, ReactNode, useReducer} from "react";
import {ActionMap} from "../@types/reducer";
import * as userClient from "../clients/UserClient"

type UserState = {
  users: User[]
}

type UserActionCreators = {
  loadUsers: () => Promise<User[]>
  deleteUser: (userId: string) => Promise<void>
  saveUser: (userId: string|undefined, data: CreateUserDto|UpdateUserDto) => Promise<User>
}

type AdminUsersContextType = UserState & UserActionCreators
const AdminUsersContext = createContext<AdminUsersContextType>({} as AdminUsersContextType)

enum ActionTypes {
  SetUsers = "SET_USERS",
  DeleteUser = "REMOVE_USER",
  SetUser = "SET_USER"
}

type UserPayload = {
  [ActionTypes.SetUsers]: { users: User[] }
  [ActionTypes.DeleteUser]: { userId: string }
  [ActionTypes.SetUser]: { user: User }
}

type UserAction = ActionMap<UserPayload>[keyof ActionMap<UserPayload>]
const Reducer = (state: UserState, action: UserAction) => {
  switch (action.type) {
    case ActionTypes.SetUsers:
      return {...state, users: action.payload.users}
    case ActionTypes.DeleteUser:
      return {
        ...state,
        users: state.users.filter(it => it.userId !== action.payload.userId)
      }
    case ActionTypes.SetUser:
      return {
        ...state,
        users: state.users.map(it =>
          it.userId === action.payload.user.userId ?
            action.payload.user :
            it
        )
      }
  }
}

const AdminUsersProvider = ({children}: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, {users: []});

  const loadUsers = async () => {
    const users = await userClient.getUsers()
    dispatch({type: ActionTypes.SetUsers, payload: {users}})
    return users
  }

  const deleteUser = async (userId: string) => {
    await userClient.deleteUser(userId)
    dispatch({type: ActionTypes.DeleteUser, payload: {userId}})
  }

  const saveUser = async(userId: string|undefined, data: CreateUserDto|UpdateUserDto) => {
    const user = !userId ? await userClient.createUser(data) : await userClient.updateUser(userId, data)
    dispatch({type: ActionTypes.SetUser, payload: {user}})
    return user
  }

  return (
    <AdminUsersContext.Provider
      value={{
        ...state,
        loadUsers,
        deleteUser,
        saveUser
      }}>
      {children}
    </AdminUsersContext.Provider>
  )
}

export { AdminUsersContext, AdminUsersProvider }