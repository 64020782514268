import { InputAdornment, TextField } from '@mui/material'
import Iconify from '../Iconify'

export function HostedContentRoomSearch(){
  return (
    <TextField
      size="small"
      // onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Search..."
      // defaultValue={searchTerm}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-outline"/>
          </InputAdornment>
        )
      }}
    />
  )
}