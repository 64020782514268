import { OauthProvider } from '../../../@types/oauth'
import Image from '../../Image'
import React from 'react'
import { Box } from '@mui/material'

export function IntegrationLogo({ provider }: { provider: OauthProvider }) {
  const src = `/assets/integrations/${provider}.png`
  return (
    <Box
      sx={{
        height: 64,
        width: 64,
        borderRadius: 8,
      }}
    >
      <Image
        src={src}
        ratio='1/1'
        backgroundType='contain'
      />
    </Box>
  )
}