export type AssetContentState = {
  order: number,
  assetId: string,
  contentType: AssetExtractedContentType,
  status: AssetContentStateStatus,
  errorCode: AssetContentStateErrorCode | null,
  errorData: object,
  updatedAt: Date
}

export enum AssetExtractedContentType {
  RAW_TEXT = 1,
  GENERATED_QUESTIONS = 2,
  DOCUMENT_SUMMARY = 3,
  IMAGE_SUMMARY = 4,
  VIDEO_SUMMARY = 5,
  OCR_TEXT = 6,
  TRANSCRIPTION = 7,
  TOPICS = 8,
  PALETTE = 9,
  LABELS = 10,
  RAW_FILE = 100
}

export enum AssetContentStateStatus {
  SUCCESS = 1,
  FAILED = 2,
  MISSING = 3,
  IN_PROGRESS = 4
}

export enum AssetContentStateErrorCode {
  GOOGLE_DOC_PRIVATE_LINK_FORBIDDEN = 4500,
  GOOGLE_DOC_LINK_MISSING_DOC_TYPE = 4501,
  GOOGLE_DOC_LINK_MISSING_DOC_ID = 4502,
  GOOGLE_DOC_INVALID_DOC_TYPE = 4503,
  GOOGLE_DOC_GONE = 4504,
  GOOGLE_DOC_UNAUTHENTICATED_NOT_FOUND = 4505,
  GOOGLE_DOC_AUTHENTICATED_NOT_FOUND = 4506,
  GOOGLE_DOC_AUTHENTICATED_NO_VALID_ACCOUNT = 4507,
  GOOGLE_DOC_UNAUTHENTICATED_UNABLE_TO_DOWNLOAD = 4510,
  GOOGLE_DOC_AUTHENTICATED_UNABLE_TO_DOWNLOAD= 4511,
}


export type AssetContentStateResponse = {
  assetId: string,
  states: AssetContentState[]
}

export type GetAssetTranscriptionResponse = AssetTranscription
export type GetAssetDocumentSummaryResponse = AssetDocumentSummary
export type GetAssetGeneratedQuestionsResponse = AssetGeneratedQuestions
export type GetAssetImageSummaryResponse = AssetImageSummary
export type GetAssetOCRContentsResponse = AssetOCRContents

export type AssetDocumentSummary = {
  summary: string,
  createdAt: Date | string
}

export type AssetImageSummary = {
  summary: string,
  createdAt: Date | string
}

export type AssetOCRContents = {
  contents: string,
  createdAt: Date | string
}

export type AssetTranscription = {
  segments: TranscriptionSegment[],
  createdAt: Date | string
}

export type TranscriptionSegment = {
  startMs: number | null,
  stopMs: number | null,
  text: string
}

export type AssetGeneratedQuestions = {
  questions: AssetGeneratedQuestion[],
  createdAt: Date | string
}

export type AssetGeneratedQuestion = {
  text: string
}