import { Stack, Table, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'
import UserAnalyticsTableHead from './UserAnalyticsTableHead'
import EmptyContent from '../../EmptyContent'
import { useUserAnalytics } from '../../../hooks/analytics/useUserAnalytics'
import UserAnalyticsFilterBar from './UserAnalyticsFilterBar'
import UserAnalyticsPagination from './UserAnalyticsPagination'
import { AnalyticsUserRow, UserSkeletonTableRow } from './UserAnalyticsTableRow'

export function UserAnalyticsTable() {

  return (
    <Stack direction='column' spacing={2}>
      <UserAnalyticsFilterBar />
      <Table stickyHeader>
        <UserAnalyticsTableHead />
        <UserAnalyticsTableBody />
      </Table>
      <UserAnalyticsPagination />
    </Stack>
  )
}


function UserAnalyticsTableBody() {
  const { data, isLoading } = useUserAnalytics()

  if (isLoading) {
    const toRender = Array.from(Array(10).keys())
    return (
      <TableBody>
        {toRender.map((it,idx) => {
          return <UserSkeletonTableRow key={it} rowNum={idx} />
        })}
      </TableBody>
    )
  }

  return (
    <TableBody>
      {
        data?.results.map((it, idx) => <AnalyticsUserRow key={it.userId} user={it} rowNum={idx} />)
      }
      {
        data?.results.length == 0 && (
          <TableRow>
            <TableCell colSpan={6}>
              <EmptyContent
                title='No results'
                description='No assets matched your filters'
              />
            </TableCell>
          </TableRow>

        )
      }
    </TableBody>
  )
}