import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify'
import { fNumber } from '../../utils/formatNumber'

type Props = {
  currentPage: number,
  pageSize: number,
  totalResultCount: number,
  scrollOnPageChange?: boolean,
  onPageChange: (pageNum: number) => void
}
export default function Pagination(
  {
    currentPage,
    pageSize,
    totalResultCount,
    scrollOnPageChange = false,
    onPageChange
  }
: Props) {
  const numPages = Math.ceil(totalResultCount / pageSize)
  const displayPageNumber = currentPage + 1
  const currentResultNumLower = Math.max(1, (currentPage * pageSize) + 1)
  const currentResultNumUpper = Math.min(totalResultCount, (displayPageNumber * pageSize))

  const scrollToTop = () => {
    if (scrollOnPageChange) {
      setTimeout(() => document.body.scrollTo({ top: 0, behavior: 'smooth' }), 0)
    }
  }

  const onNextPage = () => {
    onPageChange(currentPage + 1)
    scrollToTop()
  }

  const onPreviousPage = () => {
    onPageChange(currentPage - 1)
    scrollToTop()
  }

  if (totalResultCount === 0) {
    return <></>
  }

  return (
    <Stack direction='row' spacing={1} alignItems='center' justifyContent='end'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <IconButton
          size='small'
          onClick={() => onPreviousPage()}
          disabled={currentPage <= 0}
        >
          <Iconify icon='eva:chevron-left-fill' />
        </IconButton>

        <Stack direction='row' spacing={.5}>
          <Typography variant='smallHighlight'>{currentResultNumLower}-{currentResultNumUpper}</Typography>
          <Typography variant='small' color='text.deemphasized'>of</Typography>
          <Typography variant='smallHighlight' color='text.deemphasized'>{fNumber(totalResultCount)}</Typography>
        </Stack>


        <IconButton
          size='small'
          disabled={currentPage >= numPages - 1}
          onClick={() => onNextPage()}
        >
          <Iconify icon='eva:chevron-right-fill' />
        </IconButton>

      </Stack>
    </Stack>
  )
}