import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getHostedContentRoomAssets,
  getHostedContentRoomBootstrap,
  loginToHostedContentRoom,
  setPCRAssetFavorite,
} from '../clients/HostedContentRoomClient'
import { GetHostedContentRoomBootstrapResponse } from '../@types/hosted-content-room'

const ONE_HOUR_IN_MS = 3_600_000

export function useHostedContentRoomBootstrap(shortCode: string | undefined) {
  return useQuery({
    enabled: Boolean(shortCode),
    queryKey: ['hosted-content-room-bootstrap', shortCode],
    queryFn: async () => getHostedContentRoomBootstrap(shortCode!!),
    staleTime: ONE_HOUR_IN_MS
  })
}

type HostedContentRoomLoginParams = {
  shortCode: string,
  password: string
}

export function useHostedContentRoomLogin() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: HostedContentRoomLoginParams) =>
      loginToHostedContentRoom(params.shortCode, params.password),
    onSuccess: async (data, { shortCode }) => {
      queryClient.setQueryData(
        ['hosted-content-room-bootstrap', shortCode],
        (oldData?: GetHostedContentRoomBootstrapResponse) => oldData ? { ...oldData, ...data } : oldData,
      )
    },
    // onError
  })
}

export function useHostedContentRoomAssets(shortCode: string){
  return useQuery({
    queryKey: ['hosted-content-room-assets', shortCode],
    queryFn: async () => getHostedContentRoomAssets(shortCode)
  })
}

export function useInfiniteContentRoomAssets(shortCode: string){
  return useInfiniteQuery({
    queryKey: ['hosted-content-room-assets', shortCode],
    queryFn: async ({pageParam: offset = 0}) => getHostedContentRoomAssets(shortCode, offset),
    getNextPageParam: (lastPage, pages) => lastPage.offset + 100
  })
}


type SetPCRAssetFavoriteParams = {
  shortCode: string,
  assetId: string,
  isFavorite: boolean
}

export function useSetPCRAssetFavorite(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: SetPCRAssetFavoriteParams) =>
      setPCRAssetFavorite(params.shortCode, params.assetId, params.isFavorite)
  })
}