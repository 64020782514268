import { ContentGPSRawPage, ContentGPSResponse } from "../../../@types/content-gps";
import { Box, Link, Popover, Stack, Typography } from "@mui/material";
import ActivityRings from "../../rings/ActivityRings";
import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import DOMPurify from "dompurify"

const RING_COLOR_TOTAL = "#00fff8"
const RING_COLOR_PARTIAL = "#afff02"
const RING_COLOR_EXACT = "#fa0e5a"

export function ContentGPSRings({ gps }: { gps: ContentGPSResponse }) {
  const assetSummary = useMemo(() => extractSummary(gps), [gps])
  const exactFillPercent = Math.min(1, assetSummary.fullMatchPages.length / gps!!.companyAverages.fullMatchPageAvg)
  const partialFillPercent = Math.min(1, assetSummary.partialMatchPages.length / gps!!.companyAverages.partialMatchPageAvg)
  const totalFillPercent = Math.min(1, assetSummary.totalMatchPages.length / gps!!.companyAverages.totalMatchPageAvg)
  const rings = [
    {
      "filledPercentage": exactFillPercent,
      "color": RING_COLOR_EXACT
    },
    {
      "filledPercentage": partialFillPercent,
      "color": RING_COLOR_PARTIAL
    },
    {
      "filledPercentage": totalFillPercent,
      "color": RING_COLOR_TOTAL
    }
  ]
  const options = {
    containerWidth: "75%",
    backgroundOpacity: .2,
    initialRadius: 40
  }


  return (
    <>
      <Typography variant="h6">Asset Exposure</Typography>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={1}
        alignItems="center"
      >
        <Box sx={{ textAlign: "center", minWidth: "55%" }}>
          <ActivityRings rings={rings} options={options}/>
        </Box>
        <Box>
          <Stack spacing={1}>
            <LegendEntry
              color={RING_COLOR_TOTAL}
              title={"Total Exposure"}
              pages={assetSummary.totalMatchPages}
              total={gps!!.companyAverages.totalMatchPageAvg}
            />
            <LegendEntry
              color={RING_COLOR_PARTIAL}
              title={"Partial Matches"}
              pages={assetSummary.partialMatchPages}
              total={gps!!.companyAverages.partialMatchPageAvg}
            />
            <LegendEntry
              color={RING_COLOR_EXACT}
              title={"Exact Matches"}
              pages={assetSummary.fullMatchPages}
              total={gps!!.companyAverages.fullMatchPageAvg}
            />
          </Stack>
          <Box>
            <Typography
              component={"div"}
              variant="small"
              color="text.deemphasized"
              mt={2}
            >
              A completed ring indicates that this asset is on par with other company assets.
            </Typography>
            <Typography
              component={"div"}
              variant="small"
              color="text.deemphasized"
              mt={2}
            >
              Content GPS scores are calculated using a normalized
              average of all compatible images for your company.
            </Typography>
          </Box>
        </Box>
      </Stack>
    </>
  )
}


function LegendEntry({ title, color, pages, total }: {
  title: string,
  color: string,
  pages: ContentGPSRawPage[],
  total: number
}) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const openPopover = (event: React.MouseEvent) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl);
  const id = open ? 'legend-popover' : undefined;

  return (
    <Stack direction="row" spacing={1}>
      <Box sx={{ height: 16, width: 16, borderRadius: .5, bgcolor: color }}/>
      <Stack direction="column" spacing={1}>
        <Typography variant="small">{title}</Typography>
        <Stack direction={"row"} spacing={.5} alignItems="center">
          <Typography
            variant="small"
            component={Link}
            sx={{ cursor: "pointer" }}
            underline="none"
            onClick={openPopover}
          >{pages.length} pages</Typography>
          <Typography
            variant="small"
            color="text.deemphasized"
          >compared to</Typography>
          <Typography
            variant="small"
            color="text.deemphasized"
          >{total} avg</Typography>
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <PagesPopoverContent pages={pages}/>
      </Popover>
    </Stack>
  )
}

function PagesPopoverContent({ pages }: { pages: ContentGPSRawPage[] }) {
  return (
    <ScrollableBox>
      <Typography variant="h4">Result Pages</Typography>
      <ul>
        {pages.map(page => {
          const cleanTitle = DOMPurify.sanitize(page.pageTitle, { ALLOWED_TAGS: [] }).trim()
          return (
            <li key={page.url}>
              <Typography
                component={Link}
                href={page.url}
                variant="small"
                target="_blank"
              >
                {cleanTitle}
              </Typography>
            </li>
          )
        })}
      </ul>
    </ScrollableBox>
  )
}

function extractSummary(gps: ContentGPSResponse | null) {
  const pages = gps?.assetResults.result?.webDetection?.pagesWithMatchingImages || []
  const partial = pages.filter(it => (it.partialMatchingImages?.length || 0) > 0)
  const full = pages.filter(it => (it.fullMatchingImages?.length || 0) > 0)
  return {
    partialMatchPages: partial,
    fullMatchPages: full,
    totalMatchPages: pages
  }
}

const ScrollableBox = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(2, 4),
    maxHeight: 300,
    maxWidth: 400
  }
})