import { Asset, AssetStorageType, AssetType } from '../../@types/asset'
import { Box, Button, Divider, Stack, Tab, Tooltip, Typography } from '@mui/material'
import Iconify from '../Iconify'
import { useDownload } from '../../hooks/useDownload'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import React, { useState } from 'react'
import { AssetDetailMetadataTab } from './tabs/AssetDetailMetadataTab'
import { AssetDetailContentGPSTab } from './tabs/AssetDetailContentGPSTab'
import { AssetDetailAITab } from './tabs/AssetDetailAITab'
import { styled } from '@mui/material/styles'
import AssetDetailAnalyticsTab from './tabs/AssetDetailAnalyticsTab'
import { AssetDetailInformationActions } from './actions/AssetDetailInformationActions'
import useAuth from '../../hooks/useAuth'
import { UserType } from '../../@types/user'
import { AssetDetailAIReadinessTab } from './tabs/AssetDetailAiReadinessTab'
import { useIsFeatureEnabled } from '../../hooks/useFeatures'
import { MassetBotTab } from './tabs/MassetBotTab'


type AssetDetailInformationProps = {
  asset: Asset
}

export function AssetDetailInformation({ asset }: AssetDetailInformationProps) {
  const { user } = useAuth()
  const [selectedTab, setSelectedTab] = useState('details')
  const { consumeAsset } = useDownload()
  const massetBotEnabled = useIsFeatureEnabled("asset-massetbot")
  const newAITabEnabled = useIsFeatureEnabled("content-extraction")


  // handlers
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue)
  }

  // calculated props
  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  const isExternal = asset.storageType === AssetStorageType.EXTERNAL_HTTP
  const isImage = asset.assetType === AssetType.IMAGE
  return (
    <Box>

      {!isExternal && (
        <Button
          variant='contained'
          startIcon={<Iconify icon={'eva:download-fill'} />}
          fullWidth
          onClick={() => consumeAsset(asset)}
          disabled={downloadDisabled}
        >
          Download
          <ThirdPartyPostfix asset={asset} />
        </Button>
      )}

      {isExternal && (
        <Button
          variant='contained'
          startIcon={<Iconify icon={'eva:link-fill'} />}
          fullWidth
          onClick={() => consumeAsset(asset)}
          disabled={downloadDisabled}
        >
          Go to linked Asset
          <ThirdPartyPostfix asset={asset} />
        </Button>
      )}

      <AssetDetailInformationActions asset={asset} />

      <TabContext value={selectedTab}>
        <TabList
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
          sx={{ mt: 2 }}
        >
          <Tab label='Details' value='details' />
          <Tab label='Analytics' value='analytics' />
          {isImage && !newAITabEnabled && (<Tab label='AI' value='ai' />)}
          {newAITabEnabled && (<Tab label='AI' value='ai' />)}
          {isImage && (<Tab label={
            <Stack direction='row' spacing={1} alignItems='center'>
              <> Content GPS</>
              <BetaTagBox>beta</BetaTagBox>
            </Stack>
          } value='gps' />)}
          { massetBotEnabled && <Tab label='MassetBot' value='massetbot' /> }
        </TabList>

        <Divider sx={{ mb: 2 }} />

        <TabPanel value='details'>
          <AssetDetailMetadataTab asset={asset} />
        </TabPanel>

        <TabPanel value='analytics'>
          <AssetDetailAnalyticsTab asset={asset} />
        </TabPanel>

        <TabPanel value='ai'>
          { !newAITabEnabled && (<AssetDetailAITab asset={asset} />) }
          { newAITabEnabled && (<AssetDetailAIReadinessTab asset={asset} />) }
        </TabPanel>

        <TabPanel value='massetbot'>
          <MassetBotTab asset={asset} />
        </TabPanel>

        <TabPanel value='gps'>
          <AssetDetailContentGPSTab asset={asset} />
        </TabPanel>
      </TabContext>

      <Box mb={4} />
    </Box>
  )
}

const BetaTagBox = styled(Box)(({ theme }) => {
  return {
    color: 'inherit',
    borderRadius: theme.spacing(.5),
    padding: '2px 4px',
    border: `1px solid`,
    backgroundColor: 'transparent',
    display: 'inline-block',
    fontSize: '10px',
    textTransform: 'lowercase',
    marginLeft: '6px',
  }
})

function ThirdPartyPostfix({ asset }: { asset: Asset }) {
  if (!asset?.isThirdParty) return <></>
  return (

    <Typography variant='standard'>
      <Stack spacing={.5} direction='row' alignItems='center'>
        <i>&nbsp;- 3rd Party </i>
        <Tooltip title='3rd Party Assets are managed by others outside your company. Take caution when sharing.'>
          <Stack>
            <Iconify icon='eva:question-mark-circle-fill' />
          </Stack>
        </Tooltip>
      </Stack>
    </Typography>
)
}