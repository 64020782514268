import {
  Alert,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {
  Asset,
  BulkImportLinkedAssetWithErrors,
  LinkedAssetsImportError,
  LinkedAssetsImportErrorType,
  LinkedAssetsImportResult
} from "../../../@types/asset";
import React, { useState } from "react";
import Iconify from "../../Iconify";

const MAX_TABLE_HEIGHT = 240

export default function ResultSummary({ result }: { result: LinkedAssetsImportResult }) {
  const defaultSelected = result.assets.length ? "created" :
    result.ignored.length ? "ignored" :
      result.errors.length ? "error" : "created"
  const [selected, setSelected] = useState(defaultSelected)
  return (
    <>
      <Stack direction="row" spacing={1}>
        <Box onClick={() => setSelected("created")}>
          <ResultHeader title="Created" value={result.assets.length} selected={selected === "created"}/>
        </Box>

        <Box onClick={() => setSelected("ignored")}>
          <ResultHeader title="Skipped" value={result.ignored.length} selected={selected === "ignored"}/>
        </Box>

        <Box onClick={() => setSelected("error")}>
          <ResultHeader title="Errors" value={result.errors.length} selected={selected === "error"}/>
        </Box>
      </Stack>

      <Box mt={2} sx={{minHeight: MAX_TABLE_HEIGHT}}>
        {selected === "created" && (
          <CreatedTable assets={result.assets}/>
        )}

        {selected === "ignored" && (
          <IgnoredTable assets={result.ignored}/>
        )}

        {selected === "error" && (
          <ErrorTable errors={result.errors}/>
        )}
      </Box>

    </>
  )
}

function ResultHeader({ title, value, selected }: { title: string, value: number, selected: boolean }) {
  const backgroundColor = selected ? "bg.mint" : "transparent"
  return (
    <Box px={3} py={1} sx={{ cursor: "pointer", bgcolor: backgroundColor }}>
      <Typography variant="h6" mb={1}>{title}</Typography>
      <Typography variant="h1">{value}</Typography>
    </Box>
  )
}

function CreatedTable({ assets }: { assets: Asset[] }) {
  if(assets.length === 0){
    return (
      <Stack direction="row" sx={{height: MAX_TABLE_HEIGHT}} alignItems="center">
        <Alert severity="info" variant="outlined" sx={{flex:1}}>
          <Typography variant="small" component="div">Looking good!</Typography>
          <Typography variant="small" color="text.deemphasized">No assets were created, but that's not necessarily a bad thing! We probably skipped them because they were duplicates.</Typography>
        </Alert>
      </Stack>
    )
  }
  return (
    <TableContainer sx={{ maxHeight: MAX_TABLE_HEIGHT }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((asset) => {
            return (
              <TableRow key={asset.assetId}>
                <TableCell>
                  <Typography variant="small">{asset.name}</Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Typography color="text.mint" sx={{lineHeight:0}}><Iconify icon={"eva:checkmark-fill"}/></Typography>
                    <Typography variant="small">Successfully created</Typography>
                  </Stack>

                </TableCell>
              </TableRow>
            )
          })}

        </TableBody>
      </Table>
    </TableContainer>
  )
}

function IgnoredTable({ assets }: { assets: Asset[] }) {
  if(assets.length === 0){
    return (
      <Stack direction="row" sx={{height: MAX_TABLE_HEIGHT}} alignItems="center">
        <Alert severity="info" variant="outlined"  sx={{flex:1}}>
          <Typography variant="small" component="div">Looking good!</Typography>
          <Typography variant="small" color="text.deemphasized">Nothing was a duplicate, so you won't see anything here for now.</Typography>
        </Alert>
      </Stack>
    )
  }
  return (
    <TableContainer sx={{ maxHeight: MAX_TABLE_HEIGHT }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((asset) => {
            return (
              <TableRow key={asset.assetId}>
                <TableCell>
                  <Typography variant="small">{asset.name}</Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography color="warning.main" sx={{lineHeight:0}}><Iconify icon={"eva:alert-triangle-outline"}/></Typography>
                    <Typography variant="small">Skipped because asset already exists</Typography>
                  </Stack>

                </TableCell>
              </TableRow>
            )
          })}

        </TableBody>
      </Table>
    </TableContainer>
  )
}

function ErrorTable({ errors }: { errors: BulkImportLinkedAssetWithErrors[] }) {
  if(errors.length === 0){
    return (
      <Stack direction="row" sx={{height: MAX_TABLE_HEIGHT}} alignItems="center">
        <Alert severity="info" variant="outlined"  sx={{flex:1}}>
          <Typography variant="small" component="div">Looking good!</Typography>
          <Typography variant="small" color="text.deemphasized">There were no errors in the upload process!</Typography>
        </Alert>
      </Stack>
    )
  }
  return (
    <TableContainer sx={{ maxHeight: MAX_TABLE_HEIGHT }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {errors.map((error, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell>
                  <Typography variant="small">{error.asset?.name || "<Missing>"}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="small">{error.asset?.url || "<Missing>"}</Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography color="error.main" sx={{lineHeight:0}}><Iconify icon={"eva:alert-triangle-outline"}/></Typography>
                    <ErrorMessages error={error} />
                  </Stack>

                </TableCell>
              </TableRow>
            )
          })}

        </TableBody>
      </Table>
    </TableContainer>
  )
}

function ErrorMessages({error} : {error: BulkImportLinkedAssetWithErrors}){
  return (
    <>
    {error.errors.map((it, idx) => {
      return <ErrorMessage key={idx} error={it} />
    })}
    </>
  )
}

function ErrorMessage({error}: {error: LinkedAssetsImportError}){
  if(error.errorType === LinkedAssetsImportErrorType.MISSING_LINK){
    return <Typography variant="small">Missing value for Link</Typography>
  }

  if(error.errorType === LinkedAssetsImportErrorType.MISSING_NAME){
    return <Typography variant="small">Missing value for Name</Typography>
  }

  return <></>
}