import { OauthProvider } from '../@types/oauth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteAccount, getAccount, getAccounts, getAccountsOfType } from '../clients/AccountsClient'

export function useAccounts(){
  return useQuery({
    queryKey: ["accounts"],
    queryFn: async() => getAccounts(),
    select: data => data.accounts
  })
}
export function useAccount(accountId: string | undefined){
  return useQuery({
    enabled: Boolean(accountId),
    queryKey: ["accounts", accountId],
    queryFn: async() => getAccount(accountId!!),
  })
}

export function useAccountsOfType(type: OauthProvider){
  return useQuery({
    queryKey: ["accounts", type],
    queryFn: async() => getAccountsOfType(type),
    select: data => data.accounts
  })
}

export function useAccountDelete(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async(accountId: string) => deleteAccount(accountId),
    onSuccess: async data => {
      queryClient.invalidateQueries(["accounts"])
    }
  })
}