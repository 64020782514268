import { useParams } from "react-router";
import * as React from "react";
import { useEffect, useState } from "react";
import Page from "../../components/Page";
import { Alert, Box, Button, Container, Paper, Stack, Typography } from "@mui/material";
import LogoFull from "../../components/LogoFull";
import { getPasswordResetToken } from "../../clients/PasswordTokenClient";
import { PasswordResetStatus } from "../../@types/password-reset";
import { styled } from "@mui/material/styles";
import PasswordResetSubmitForm from "./PasswordResetSubmitForm";
import { useNavigate } from "react-router-dom";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  },
  background: theme.palette.background.default
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 425,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0)
}));


export default function PasswordResetSubmit() {

  return (
    <Page title="Account Setup">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Paper elevation={4} sx={{ p: 4 }}>
              <LogoFull sx={{ mb: 4, px: 8 }} />
              <PasswordResetSubmitContent />
            </Paper>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

function PasswordResetSubmitContent() {
  const { resetToken = "" } = useParams();
  const [loading, setLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (resetToken) {
      getPasswordResetToken(resetToken)
        .then((dto) => {
          setIsValidToken(dto.status === PasswordResetStatus.VALID);
        })
        .catch((e) => {
          setIsValidToken(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [resetToken]);

  const onSuccess = () => setSuccess(true)

  if(success){
    return <ResetComplete />
  }

  return (
    <>
      {/* if the token is invalid */}
      {
        !isValidToken && !loading && <InvalidToken />
      }

      {/* if the token is valid */}
      {
        isValidToken && <ValidToken resetToken={resetToken} onSuccess={onSuccess}/>
      }
    </>
  );
}

function ResetComplete() {
  const navigate = useNavigate();

  return (
    <Stack direction="column" spacing={4}>
      <Alert severity="info" variant="outlined">
        <Typography>Your password has been reset!</Typography>
      </Alert>
      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => navigate("/login")}
      >
        Back to login
      </Button>
    </Stack>
  )
}

function InvalidToken() {
  const navigate = useNavigate();

  return (
    <>
      <Alert severity="warning" variant="outlined">
        <Typography> Your password reset token is invalid</Typography>
        <Typography
          variant="small"
          color="text.mint"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/password-reset")}>
          Click here to request a new one
        </Typography>
      </Alert>
    </>
  )
}

function ValidToken({ resetToken, onSuccess }: { resetToken: string, onSuccess: () => void }) {
  return (
    <>
      <Stack direction="column" alignItems="center" spacing={2} sx={{ mb: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h3" align="center">
            Let's reset your password
          </Typography>
        </Box>

        <Typography align="center">
          Enter a new password following the provided guidelines
        </Typography>
      </Stack>

      <PasswordResetSubmitForm resetToken={resetToken} onSuccess={onSuccess} />
    </>
  );
}