import Page from '../../components/Page'
import { useInfiniteContentRoomAssets } from '../../hooks/useHostedContentRoom'
import { useParams } from 'react-router'
import { Button } from '@mui/material'
import { HostedContentRoomAsset } from '../../@types/hosted-content-room'
import { AssetMasonry } from '../../components/hosted-content-room/AssetMasonry'
import React from 'react'

export function HostedContentRoomLanding(){
  const { shortCode } = useParams()
  const { data: assetPages, isFetchingNextPage, fetchNextPage } = useInfiniteContentRoomAssets(shortCode!!)
  const allAssets = assetPages?.pages.reduce((memo, it) => {
    return [...memo, ...it.all]
  }, [] as HostedContentRoomAsset[]) || []
  return (
    <Page title="Explore your content" >
      <AssetMasonry assets={allAssets} />
      <Button
        onClick={() => fetchNextPage()}
        disabled={isFetchingNextPage}
      >
        Load More
      </Button>
    </Page>
  )
}