import { useTheme } from '@mui/material/styles'
import { Avatar, Stack, Typography } from '@mui/material'
import { AVATAR_SIZE, ICON_SIZE } from '../../../admin/groups/field/GroupMembersField'
import Iconify from '../../../Iconify'
import React from 'react'
import { ASSET_SHARE_PADDING_X } from './AssetShareField'

export function NoShareMatchesItem() {
  const theme = useTheme()
  return (
    <Stack direction='row' spacing={2} alignItems='center' py={2} px={ASSET_SHARE_PADDING_X}>
      <Avatar sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: theme.palette.bg.warmGrey2 }}>
        <Iconify icon='eva:person-outline' sx={{ height: ICON_SIZE, width: ICON_SIZE }} />
      </Avatar>
      <Typography>No matches</Typography>
    </Stack>
  )
}
