import React, { useState } from 'react'
import { Box, Button, Dialog, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material'
import Iconify from '../../Iconify'
import Typography from '@mui/material/Typography'
import { useAssetGeneratedQuestions } from '../../../hooks/useAssetContent'
import { Asset } from '../../../@types/asset'

interface ViewTranscriptPopoverProps {
  asset: Asset
}

export function ViewGeneratedQuestionsPopover({ asset }: ViewTranscriptPopoverProps) {
  const [open, setOpen] = useState(false)
  const { data: dto } = useAssetGeneratedQuestions(asset.assetId, open)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={handleOpen}
      >
        View Generated Questions
      </Button>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth={true}
        onClose={handleClose}
      >

        <Box p={7}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Typography variant='h2' paragraph>Questions (Auto-Generated)</Typography>

          <Box
            sx={{
              maxHeight: 640,
              overflow: 'scroll',
              whitespace: 'pre-line',
            }}
          >
            <Table>
              <TableBody>
                {dto?.questions.map(function(it, idx) {
                  return (
                    <TableRow key={idx}>
                      <TableCell
                        sx={{ textAlign: 'right' }}
                      >
                        <Typography
                          variant='standard'
                          color="text.deemphasized"
                          sx={{ fontFamily: "monospace"}}
                        >
                          {idx + 1}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='standard'>
                          {it.text}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>

            </Table>


          </Box>
        </Box>
      </Dialog>
    </>
  )
}