import React, { useContext, useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { ASSET_SHARE_PADDING_X, InternalFormShareItem } from './AssetShareField'
import { NoShareMatchesItem } from './NoShareMatchesItem'
import { ShareItemToAdd } from './ShareItemToAdd'
import { useSharingSearch } from '../../../../hooks/useSharing'
import { AssetShareFieldContext } from './AssetShareFieldContext'

type NotSharedListProps = {
  items: InternalFormShareItem[],
}

export function NotSharedList({ items }: NotSharedListProps) {
  const { searchTerm } = useContext(AssetShareFieldContext)
  const { data, isFetching } = useSharingSearch(searchTerm, 100)
  const itemsById = useMemo(() => keyBy(items, 'uniqueId'), [items])
  const itemsFromServerSearch = data?.shares || []
  const itemsNotShared = itemsFromServerSearch.filter(it => !itemsById[it.uniqueId]).slice(0, 15)
  return (
    <Box>
      <Box px={ASSET_SHARE_PADDING_X}><Typography variant='smallHighlight'>Not shared with</Typography></Box>
      {isFetching && <Box py={2} px={3.5}><CircularProgress size={16} /></Box>}
      {!isFetching && (
        <Stack>
          {itemsNotShared.map(it => <ShareItemToAdd key={it.uniqueId} item={it} />)}
        </Stack>
      )}
      {!isFetching && itemsNotShared.length === 0 && <NoShareMatchesItem />}
    </Box>
  )
}
