import { useQuery } from '@tanstack/react-query'
import {
  getAssetDocumentSummary,
  getAssetGeneratedQuestions, getAssetImageSummary, getAssetOCRContents,
  getAssetTranscription,
} from '../clients/AssetContentClient'

export function useAssetTranscription(assetId: string, enabled: boolean = true){
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, "transcription"],
    queryFn: () => getAssetTranscription(assetId)
  })
}

export function useAssetDocumentSummary(assetId: string, enabled: boolean = true){
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, "document-summary"],
    queryFn: () => getAssetDocumentSummary(assetId)
  })
}

export function useAssetGeneratedQuestions(assetId: string, enabled: boolean = true){
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, "generated-questions"],
    queryFn: () => getAssetGeneratedQuestions(assetId)
  })
}

export function useAssetImageSummary(assetId: string, enabled: boolean = true){
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, "image-summary"],
    queryFn: () => getAssetImageSummary(assetId)
  })
}

export function useAssetOCRContents(assetId: string, enabled: boolean = true){
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, "ocr-contents"],
    queryFn: () => getAssetOCRContents(assetId)
  })
}
