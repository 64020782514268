import { Button, ClickAwayListener, MenuItem, MenuList, Popover, Typography } from '@mui/material'
import Iconify from '../Iconify'
import React, { useContext, useState } from 'react'
import { AdminAnalyticsContext } from '../../contexts/AdminAnalyticsContext'
import { AnalyticType, CategoryValueFilterMode } from '../../@types/analytics/analytics-asset'
import { GeneralAccessType } from '../../@types/sharing'

export function AddFilterButton() {
  const { addTagFilter, addOccurrenceFilter, addSharingFilter } = useContext(AdminAnalyticsContext)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  // handlers
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleExcludeFilter = () => {
    addTagFilter([], false, CategoryValueFilterMode.ONE_OF, true)
    handleClose()
  }
  const handleOccurenceGTFilter = () => {
    addOccurrenceFilter(AnalyticType.VIEW, 'gt', 0, true)
    handleClose()
  }
  const handleOccurenceLTFilter = () => {
    addOccurrenceFilter(AnalyticType.VIEW, 'lt', 0, true)
    handleClose()
  }
  const handleOccurenceEQFilter = () => {
    addOccurrenceFilter(AnalyticType.VIEW, 'eq', 0, true)
    handleClose()
  }
  const handleSharingFilter = () => {
    addSharingFilter(GeneralAccessType.RESTRICTED, true)
    handleClose()
  }

  // calculated proprs
  const open = Boolean(anchorEl)
  const id = open ? 'new-filter-popover' : undefined

  return (
    <>
      {/* trigger */}
      <Button
        size='small'
        color='primary'
        variant='text'
        startIcon={<Iconify icon='eva:funnel-outline' />}
        onClick={handleOpen}
      >
        Add Advanced Filter
      </Button>

      {/* content */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'top', horizontal: -4 }}
        sx={{ borderRadius: 0 }}
        disableScrollLock={true}
        PaperProps={{ sx: { boxShadow: 3 } }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList dense={true}>
            <MenuItem onClick={handleSharingFilter}>
              <Typography variant='small'> Filter by sharing </Typography>
            </MenuItem>
            <MenuItem onClick={handleExcludeFilter}>
              <Typography variant='small'> Exclude tag(s) </Typography>
            </MenuItem>
            <MenuItem onClick={handleOccurenceGTFilter}>
              <Typography variant='small'> Happened more than X times </Typography>
            </MenuItem>
            <MenuItem onClick={handleOccurenceLTFilter}>
              <Typography variant='small'> Happened less than X times </Typography>
            </MenuItem>
            <MenuItem onClick={handleOccurenceEQFilter}>
              <Typography variant='small'> Happened X times </Typography>
            </MenuItem>
          </MenuList>

        </ClickAwayListener>
      </Popover>
    </>
  )
}