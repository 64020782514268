import { Button } from '@mui/material'

export function CallToActionButton(){
  return (
    <Button
      variant="contained"
      sx={{whiteSpace: "nowrap"}}
    >
      Schedule a Demo
    </Button>
  )
}