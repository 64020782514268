import React, { useMemo } from 'react'
import { Avatar, Box, BoxProps, Stack, Typography } from '@mui/material'
import Image from '../../../Image'
import { useTheme } from '@mui/material/styles'
import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room'
import { useParams } from 'react-router'
import { fDate } from '../../../../utils/formatTime'
import { parseJSON } from 'date-fns'
import { AssetStats } from '../AssetStats'


type HalfHeightImageCardProps = {
  asset: HostedContentRoomAsset
}

export function HalfHeightImageCard({ asset }: HalfHeightImageCardProps) {
  const { shortCode } = useParams()
  const theme = useTheme()
  const createdAt = useMemo(() => parseJSON(asset.createdAt), [asset])
  return (
    <Stack direction='column'>
      <Image
        alt={asset.name}
        src={`/api/v1/cr/${shortCode}/assets/${asset.assetId}/previews/${asset.assetPreviewId}`}
        sx={{
          width: 1,
        }}
      />
      <Box
        p={2}
        sx={{
          position: 'relative',
        }}
      >
        <AvatarShape
          sx={{
            position: 'absolute',
            left: -32,
            top: -23,
            zIndex: 10,
            height: 40,
          }}
        />
        <Avatar
          sx={{
            zIndex: 11,
            position: 'absolute',
            top: -19,
            left: 20,
            background: theme.palette.primary.light,
          }}
        />

        <Stack direction='column' spacing={1} mt={3}>
          <Typography variant='small' color='text.deepmhasized'>{fDate(createdAt)}</Typography>
          <Typography variant='standardHighlight' noWrap>{asset.name}</Typography>
        </Stack>

        <Box mt={3}>
          <AssetStats asset={asset} />
        </Box>

      </Box>
    </Stack>
  )
}


function AvatarShape({ sx, ...other }: BoxProps) {
  return (
    <Box
      component='svg'
      width={144}
      height={62}
      fill='none'
      viewBox='0 0 144 62'
      xmlns='http://www.w3.org/2000/svg'
      sx={{
        color: 'background.paper',
        ...sx,
      }}
      {...other}
    >
      <path
        d='m111.34 23.88c-10.62-10.46-18.5-23.88-38.74-23.88h-1.2c-20.24 0-28.12 13.42-38.74 23.88-7.72 9.64-19.44 11.74-32.66 12.12v26h144v-26c-13.22-.38-24.94-2.48-32.66-12.12z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </Box>
  )
}