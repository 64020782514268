import { Asset } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { ViewGeneratedQuestionsPopover } from './ViewGeneratedQuestionsPopover'

export function GeneratedQuestionsReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  return (
    <ReadinessContainer
      icon="eva:message-square-outline"
      state={state}
      title="Questions"
      subtitle="Predict what questions employees and prospects will ask that are answered by this asset."
    >
      {isReady && (<ViewGeneratedQuestionsPopover asset={asset} />)}
    </ReadinessContainer>
  )
}