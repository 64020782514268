import { Box, Button, Container, Typography } from '@mui/material'
import { MotionContainer, varBounce } from '../animate'
import { m } from 'framer-motion'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import React from 'react'
import Image from '../Image'
import Iconify from '../Iconify'

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

export function AssetForbidden({ assetId }: { assetId?: string }) {
  const handleRequestAccess = () => {}
  return (
    <Container component={MotionContainer}>
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant='h2' paragraph>
            Hmmm, it looks like you don't have access to that asset...
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Are you sure it has been shared with you? You may need to reach out to whoever sent you the asset link and ask for permission.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Image
            disabledEffect
            visibleByDefault
            alt='empty content'
            src='/assets/illustrations/illustration_forbidden_asset.svg'
            sx={{ height: 180, my: { xs: 5, sm: 10 } }}
          />
        </m.div>

        {/*<Button*/}
        {/*  size="large"*/}
        {/*  variant="contained"*/}
        {/*  onClick={handleRequestAccess}*/}
        {/*  startIcon={<Iconify icon="eva:unlock-outline"/>}*/}
        {/*>*/}
        {/*  Request Access*/}
        {/*</Button>*/}

        <Box mt={4}>
          <Button
            to='/assets/search'
            variant='outlined'
            component={RouterLink}
            startIcon={<Iconify icon='eva:chevron-left-fill' />}
          >
            Back to Assets
          </Button>
        </Box>

      </ContentStyle>
    </Container>
  )
}