import { FormProvider, RHFTextField } from "../../hook-form";
import { useContext, useState } from "react";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import RHFPasswordField from "../../hook-form/RHFPasswordField";
import { PASSWORD_RESULT_MESSAGES, UpdatePasswordResultType } from "../../../@types/profile";

type FormProps = {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

const formValidator = yupResolver(Yup.object().shape({
  currentPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string()
    .password()
    .min(12)
    .required("New Password is required"),
  newPasswordConfirmation:  Yup.string().test('passwords-match', 'Passwords must match', function(value){
    return this.parent.newPassword === value
  }),
}), {abortEarly: false})

const DEFAULT_VALUES = { currentPassword: "", newPassword: "", newPasswordConfirmation: "" }

export default function ProfileResetPasswordForm(){
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false)
  const { updatePassword } = useContext(ProfileContext)
  const methods = useForm<FormProps>({
    resolver: formValidator,
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: DEFAULT_VALUES
  })
  const {handleSubmit, formState, setError, reset} = methods

  const handleUpdateError = (result: UpdatePasswordResultType) => {
    const errorMessage = PASSWORD_RESULT_MESSAGES[result] || PASSWORD_RESULT_MESSAGES[UpdatePasswordResultType.UNKNOWN_ERROR]
    enqueueSnackbar((<>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="smallHighlight">Hmmm...</Typography>
        <Typography component="div" variant="small" noWrap>{errorMessage}</Typography>
      </Stack>
    </>), { variant: "error"})
    if(result === UpdatePasswordResultType.WRONG_CURRENT_PASSWORD){
      setError("currentPassword", { message: "Invalid password" })
    }
  }

  const onSubmit = async (data: FormProps) => {
    setSaving(true)
    try {
      const updateResponseDto = await updatePassword(data)
      if(updateResponseDto.result === UpdatePasswordResultType.SUCCESS){
        enqueueSnackbar((<>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="smallHighlight">Success!</Typography>
            <Typography component="div" variant="small" noWrap>Your password was updated!</Typography>
          </Stack>
        </>))
        reset()
      } else{
        handleUpdateError(updateResponseDto.result)
      }
    } catch (e){
      handleUpdateError(UpdatePasswordResultType.UNKNOWN_ERROR)
    }
    finally {
      setSaving(false)
    }
  }

  return (
    <FormProvider methods={methods}>
      <Box maxWidth={440}>
        <Stack direction="column" spacing={3}>
          <RHFTextField
            name="currentPassword"
            label="Current Password"
            type="password"
            size="small"
            variant="filled"
          />

          <RHFPasswordField
            name="newPassword"
            label="New Password"
            size="small"
            variant="filled"
          />

          <RHFTextField
            name="newPasswordConfirmation"
            label="Confirm your New Password"
            type="password"
            size="small"
            variant="filled"
          />

          <Box>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={saving}
              disabled={!formState.isValid}
            >
              Update Password
            </LoadingButton>
          </Box>

        </Stack>

      </Box>
    </FormProvider>
  )
}