import { Box, Button, Stack, Typography } from '@mui/material'
import { Asset, AssetStorageType } from '../../../../@types/asset'
import { AssetContentState } from '../../../../@types/asset-content'
import { fDate, fDateTime } from '../../../../utils/formatTime'
import { retryRawFileExtraction } from '../../../../clients/AssetContentStateClient'

export function RawFileGenericError({ asset, state }: { asset: Asset, state: AssetContentState }) {
  if (asset.storageType == AssetStorageType.EXTERNAL_HTTP) {
    return <GenericRemoteError asset={asset} state={state} />
  }

  // catch all
  return (
    <Typography>It looks like something went wrong trying to get this file that we didn't expect.</Typography>
  )
}

function GenericRemoteError({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const handleRetryDownloadClick = () => retryRawFileExtraction(asset.assetId)
  return (
    <Stack spacing={2}>
      <Typography variant='smallHighlight'>Explanation of your issue:</Typography>
      <Typography variant='small'>Something went wrong downloading this asset. This can happen if: </Typography>
      <Typography variant='small' sx={{pl: 4}}>
        <ol>
          <li>Your link is private or inaccessible from the public internet</li>
          <li>Your link is password-protected</li>
          <li>The linked location is blocking Masset traffic</li>
        </ol>
      </Typography>
      <Typography variant="small">We last attempted to download this asset <b>{fDateTime(state.updatedAt)}</b></Typography>
      <Typography variant="small">If you believe you've mitigated this issue, click below to retry the download.</Typography>
      <Box>
        <Button
          variant="outlined"
          size="small"
          onClick={handleRetryDownloadClick}
        >
          Retry Download
        </Button>
      </Box>
    </Stack>
  )
}