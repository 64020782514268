import { useForm } from "react-hook-form";
import { User, UserType } from "../../../@types/user";
import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { FormProvider, RHFTextField } from "../../hook-form";
import RHFUserTypeSelector from "./RHFUserTypeSelector";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useState } from "react";
import { AdminUsersContext } from "../../../contexts/AdminUsersContext";
import ActionBar from "../../action/ActionBar";

type InternalFormProps = {
  firstName: string
  lastName: string
  email: string
  userType: UserType
}

type EditUserFormProps = {
  user: User | undefined
}

const getDefaults = (user: User | undefined) => ({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    userType: user?.userType || UserType.VIEWER
})

// todo: validate that the current user isn't demoting themself
// todo: validate that the added email is permitted for this company
export function EditUserForm({ user } : EditUserFormProps){
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false)
  const { saveUser } = useContext(AdminUsersContext)

  const methods = useForm<InternalFormProps>({
    defaultValues: getDefaults(user)
  })
  const {handleSubmit, reset} = methods

  useEffect(() => reset(getDefaults(user)), [user])

  const onSubmit = async (data: InternalFormProps) => {
    setSaving(true)
    try {
      await saveUser(user?.userId, data)
      enqueueSnackbar((<>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="smallHighlight">Success!</Typography>
          <Typography component="div" variant="small" noWrap>A new user was created!</Typography>
        </Stack>
      </>))
      navigate("/admin/users")
    } finally {
      setSaving(false)
    }
  }

  const onCancel = () => {
    enqueueSnackbar((<>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="smallHighlight">Cancelled.</Typography>
        <Typography component="div" variant="small" noWrap>You have cancelled your new user creation</Typography>
      </Stack>
    </>))
    navigate("/admin/users")
  }

  return (
    <>
      <Box maxWidth={440}>
        <FormProvider methods={methods}>
          <Stack direction="column" spacing={3}>
            <RHFTextField
              name="firstName"
              label="First Name"
              variant="filled"
              size="small"
            />
            <RHFTextField
              name="lastName"
              label="Last Name"
              variant="filled"
              size="small"
            />
            <RHFTextField
              name="email"
              label="Email"
              variant="filled"
              size="small"
            />
          </Stack>

          <Typography variant="h3" mt={8} mb={2}>Permissions</Typography>
          <RHFUserTypeSelector name="userType" />

        </FormProvider>
      </Box>

      <Drawer
        anchor="bottom"
        variant="permanent"
      >
        <ActionBar
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={saving}
          >
            Save
          </LoadingButton>
        </ActionBar>
      </Drawer>
    </>
  )
}