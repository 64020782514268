import TotalWidget from '../../../asset-detail/tabs/analytics/common/TotalWidget'
import React from 'react'
import { useUserTotalAssetFlags } from '../../../../hooks/analytics/useUserAnalytics'
import FlagAssetIllustration from '../../../../assets/illustration_flag_asset'

export default function UserAssetFlagsTotal({ userId }: { userId: string }) {
  const { data, isLoading } = useUserTotalAssetFlags(userId)

  if(isLoading){
    return <></>
  }

  return (
    <TotalWidget
      title="Total Flags"
      total={data?.total || 0}
      icon={<FlagAssetIllustration />}
    />
  )
}