import { styled } from '@mui/material/styles'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import Page from '../../../components/Page'
import Iconify from '../../../components/Iconify'
import { useNavigate } from 'react-router-dom'

const RootStyle = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 425,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

export function SlackOauthCancellation(){
  const navigate = useNavigate()
  return (
    <Page title='Slack Registration'>
      <RootStyle>
        <Container maxWidth='sm'>
          <ContentStyle>
            <Stack direction='column' spacing={4}>
              <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    width: 64,
                    height: 64,
                    borderRadius: 1,
                    background: '#fcfcfc',
                  }}
                >
                  <Box
                    sx={{ width: 36, height: 36 }}
                    component='img'
                    src='/assets/icons/logos/slack_logo.png'
                  />
                </Stack>

                <Iconify icon='eva:plus-fill' sx={{ height: 24, width: 24 }} />

                <Box
                  sx={{ width: 64, height: 64, borderRadius: 1 }}
                  component='img'
                  src='/assets/icons/logos/masset_slack_logo_512x512.jpg'
                />
              </Stack>

              <Typography variant='h1' component='span' textAlign='center'>
                Masset installation was&nbsp;
                <Typography variant='h1' component='span' color='text.red'>cancelled</Typography>
                &nbsp;before completion
              </Typography>

              <Typography color='text.red' textAlign='center'>
                You won't be able to use Masset and Slack together until you complete the installation process.
              </Typography>

              <Button
                fullWidth
                variant="contained"
                color='primary'
                onClick={() => navigate('/')}
              >Go to Masset</Button>
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}