import { useWatch } from 'react-hook-form'
import { ShareLevel, ShareType, SharingItem } from '../../../../@types/sharing'
import React, { useContext, useState } from 'react'
import { Box, InputAdornment, TextField } from '@mui/material'
import Iconify from '../../../Iconify'
import { DefaultSharingItemsList } from './DefaultSharingItemsList'
import { SharingSearchResults } from './SharingSearchResults'
import { AssetShareFieldContext, AssetShareFieldProvider } from './AssetShareFieldContext'

export type InternalFormShareItem = {
  uniqueId: string,
  shareType: ShareType
  shareLevel: ShareLevel
  share: SharingItem
}

const SEARCH_CONTENT_HEIGHT = 350
export const ASSET_SHARE_AVATAR_SIZE = 40
export const ASSET_SHARE_ICON_SIZE = ASSET_SHARE_AVATAR_SIZE / 2
export const ASSET_SHARE_PADDING_X = 5

export function AssetShareField({ name }: { name: string }) {
  const items = useWatch({ name }) || [] as InternalFormShareItem[]
  return (
    <AssetShareFieldProvider fieldName={name}>
      <Box px={ASSET_SHARE_PADDING_X} mb={2}>
        <AssetShareSearchField />
      </Box>
      <Box
        sx={{
          height: SEARCH_CONTENT_HEIGHT,
          overflow: 'scroll',
        }}
      >
        <DefaultSharingItemsList items={items} />
        <SharingSearchResults items={items} />
      </Box>
    </AssetShareFieldProvider>
  )
}

function AssetShareSearchField() {
  const { searchTerm, setSearchTerm } = useContext(AssetShareFieldContext)
  const handleSearchTermClear = () => setSearchTerm('')
  return (
    <TextField
      fullWidth
      size='small'
      value={searchTerm}
      onChange={(ev) => setSearchTerm(ev.target.value)}
      placeholder='Search...'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Iconify icon='eva:search-outline' />
          </InputAdornment>
        ),
        endAdornment: !searchTerm ? undefined : (
          <InputAdornment position='end'>
            <Iconify icon='eva:close-outline' sx={{ cursor: 'pointer' }} onClick={handleSearchTermClear} />
          </InputAdornment>
        ),
      }}
    />
  )
}