import { Stack, Typography } from '@mui/material'
import Page from '../../components/Page'
import { useAccounts } from '../../hooks/useAccounts'
import React from 'react'
import { IntegrationAccount } from '../../components/profile/integrations/IntegrationAccount'
import EmptyContent from '../../components/EmptyContent'

export default function ProfileIntegrations() {
  // for now, accounts == integrations.
  // This probably won't always hold, but until we have a bunch of apps, its close enough
  const { data, isLoading } = useAccounts()
  const accounts = data || []
  return (
    <Page title='Security' pb={12}>
      <Typography
        variant='h2'
        mb={2}
      >
        Installed Apps
      </Typography>
      <Typography
        variant='standard'
      >
        Manage the personal apps and service that you have connected to Masset. System-wide apps are be managed by
        Admins.
      </Typography>

      <Stack spacing={2} mt={4}>
        {accounts.map(it => {
          return (
            <IntegrationAccount key={it.accountId} account={it} />
          )
        })}
      </Stack>

      {!isLoading && accounts.length == 0 && (
       <EmptyContent title="No integrations are currently connected" />
      )}

    </Page>
  )
}