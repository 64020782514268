import { HostedContentRoomAsset } from '../../../@types/hosted-content-room'
import { styled } from '@mui/material/styles'
import { Box, Card, Paper } from '@mui/material'
import { ContactCard } from './cards/ContactCard'
import { ContainedImageCard } from './cards/ContainedImageCard'
import { FadeToBlackOverlaidImageCard } from './cards/FadeToBlackOverlaidImageCard'
import { FadeToBlackOverlaidImageCTACard } from './cards/FadeToBlackOverlaidImageCTACard'
import { OverlaidImageCard } from './cards/OverlaidImageCard'
import { HalfHeightImageCard } from './cards/HalfHeightImageCard'
import { HalfHeightImageTallCard } from './cards/HalfHeightImageTallCard'

type ContentRoomAssetProps = {
  asset: HostedContentRoomAsset
}



export function ContentRoomAsset({asset}: ContentRoomAssetProps) {
  // todo: inject a Contact card for the owner of the room
  // todo: inject a "reach out" card

  switch(asset.assetId.charAt(0)){
    case '0':
    case '1':
    case '2':
      return <Root elevation={3}><ContainedImageCard asset={asset} /></Root>
    case '3':
    case '4':
    case '5':
      return <Root elevation={3}><FadeToBlackOverlaidImageCTACard /></Root>
    case '6':
    case '7':
    case '8':
      return <Root elevation={3}><FadeToBlackOverlaidImageCard asset={asset} /></Root>
    case '9':
    case 'a':
    case 'b':
      return <Root elevation={3}><HalfHeightImageCard asset={asset} /></Root>
    case 'c':
    case 'd':
      return <Root elevation={3}><HalfHeightImageTallCard /></Root>
    case 'e':
    case 'f':
    default:
      return <Root elevation={3}><OverlaidImageCard asset={asset} /></Root>
  }
}

const Root = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 16,
  display: 'block',
  overflow: "hidden"
}))

// Note: we probably want carousel versions of all of these...
// card with background and title/description overlap (http://localhost:5173/dashboard)
