import { Asset } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { OCRContents } from './OCRContents'

export function OCRTextReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  return (
    <ReadinessContainer
      icon="ph:scan"
      state={state}
      title="Text Detection (OCR)"
      subtitle="Increase searchability by extracting relevant text been extracted from images."
    >
      {isReady && (<OCRContents asset={asset}/>)}
    </ReadinessContainer>
  )
}