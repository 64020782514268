import { AssetPalette } from '../../../@types/asset'
import React from 'react'
import { Box, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'

type SimpleAssetPaletteProps = {
  palette?: AssetPalette | null
}
export function SimpleAssetPalette({palette} : SimpleAssetPaletteProps){
  const theme = useTheme()
  if(!palette) return <></>
  return (
    <Stack direction="row" spacing={-.75}>
      { palette?.colors.map((it, idx) => {
        return (
          <Box key={idx} sx={{
            borderRadius: "50%",
            background: it.hex,
            width: 16,
            height: 16,
            zIndex: 10-idx,
            border: `2px solid ${theme.palette.background.paper}`
          }}/>
        )
      })}
    </Stack>
  )
}
