import { Account } from '../../../@types/account'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { IntegrationLogo } from './IntegrationLogo'
import { IntegrationDescription } from './IntegrationDescription'
import { IntegrationActions } from './IntegrationActions'
import React from 'react'
import Iconify from '../../Iconify'
import { Link } from 'react-router-dom'

type IntegrationAccountProps = {
  account: Account
}
export function IntegrationAccount({account} : IntegrationAccountProps){
  return (
    <Link to={`/profile/integrations/details/${account.accountId}`} style={{textDecoration: "none"}}>
      <Paper sx={{p:3, cursor: "pointer"}}>
        <Stack direction="row" spacing={2} alignItems="top">
          <IntegrationLogo provider={account.provider} />
          <Box sx={{flex: 1}}>
            <IntegrationDescription account={account} />
          </Box>
          <IntegrationActions account={account} />
          <Stack direction="row" alignItems="center" pr={2}>
            <Iconify icon="ph:caret-right" sx={{width: 24, height: 24}}/>
          </Stack>
        </Stack>
      </Paper>
    </Link>
  )
}