import {User} from "../../../@types/user";
import Iconify from "../../Iconify";
import {Box, Button, Dialog, IconButton, Stack, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { useContext, useState } from "react";
import { AdminUsersContext } from "../../../contexts/AdminUsersContext";

type UserDeleteButtonProps = {
  user: User
}
export function UserDeleteButton({ user } : UserDeleteButtonProps){
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { deleteUser } = useContext(AdminUsersContext)

  const onDeleteUserClick = async () => {
    setLoading(true)
    await deleteUser(user.userId)
    setLoading(false)
    setOpen(false)
  }

  return (
    <>
      <Button
        size="small"
        variant="text"
        color="red"
        onClick={() => setOpen(true)}
        startIcon={ <Iconify color="text.red" icon='eva:trash-2-outline' /> }
        sx={{ "& .MuiButton-startIcon" : { marginRight: "4px", marginTop: "-2px" } }}
      >Delete</Button>

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7} sx={{textAlign: "center"}}>
          <Box sx={{position: "absolute", right: 0, top: 0, mr: 1, mt: 1}}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon="eva:close-fill"/>
            </IconButton>
          </Box>

          <Iconify sx={{mb: 2, fontSize: 48}} icon="eva:alert-circle-outline" color="text.mint" />

          <Typography variant="h2" mb={4}>
            Are you sure you want to delete this user?
          </Typography>

          <Typography variant="standard" color="text.secondary" component="div" mb={4}>
            Deleting the user will permanently delete this account with Masset,
            and will no longer have any access to the Masset portal.
          </Typography>

          <Stack spacing={2} >
            <LoadingButton
              loadingPosition="start"
              loading={loading}
              fullWidth={true}
              variant="contained"
              color="primary"
              size="large"
              onClick={onDeleteUserClick}
            >
              Delete User
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="outlined"
              size="large"
              onClick={() => { setOpen(false) }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}