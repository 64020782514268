import { Asset } from '../../../../@types/asset'
import { AssetContentState } from '../../../../@types/asset-content'
import { Alert, AlertTitle, Box, Button, Stack, Typography } from '@mui/material'
import { useOauthPopup } from '../../../../hooks/useOauth'
import { retryExtractionWorkflow } from '../../../../clients/AssetContentStateClient'
import { useDrivePicker } from '../../../google-picker/useDrivePicker'
import { useAccountsOfType } from '../../../../hooks/useAccounts'
import { OauthProvider } from '../../../../@types/oauth'
import Iconify from '../../../Iconify'
import React, { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Account } from '../../../../@types/account'
import { useGoogleDriveFileAccess } from '../../../../hooks/integrations/useGoogleDrive'

const ICON_SIZE = 24

// todo: provide the ability to manually upload raw files if desired
// todo: clean up these components. They aren't great
// todo: clean up how retrying downloads work
// todo: move retry workflow to mutation
// todo: some sort of loading state when workflow is retried

export function RawFileGoogleDocPrivateLinkError({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const { data: accounts } = useAccountsOfType(OauthProvider.GOOGLE_DRIVE)
  const handleRetryDownloadClick = () => retryExtractionWorkflow(asset.assetId)
  const fileId = useMemo(() => {
    const ID_EXTRACTION_PATTERN = /\/d\/(.*?)\//g
    const parts = ID_EXTRACTION_PATTERN.exec(asset.storageExternalRef) || []
    return parts[1]
  }, [asset.storageExternalRef])

  return (
    <Stack spacing={1}>
      <Typography variant='small' paragraph>
        The Google Drive link you provided appears to be private. Unless granted access, Masset cannot access private
        Google Drive links. Please take one of the steps below.
      </Typography>

      <ConnectYourAccountStep
        accounts={accounts}
        asset={asset}
      />
      <GrantAccessStep
        accounts={accounts || []}
        asset={asset}
        fileId={fileId}
      />

      <Box pt={1}>
        <Typography variant='small'>Everything ready? </Typography>
        <Typography
          variant='small'
          onClick={handleRetryDownloadClick}
          color='primary'
          sx={{ cursor: 'pointer' }}
        >
          &nbsp; Redownload from Google Drive
        </Typography>
      </Box>
    </Stack>
  )
}

function ConnectYourAccountStep({ accounts, asset }: { accounts: Account[] | undefined, asset: Asset }) {
  const queryClient = useQueryClient()

  const handleOauthSuccess = (accountId: string) => {
    queryClient.invalidateQueries(['accounts'])
    queryClient.invalidateQueries(['google-drive-file-access'])
  }
  const { trigger } = useOauthPopup({ onSuccess: handleOauthSuccess })
  const handleConnectClick = () => trigger('google-drive')

  if (!accounts) return <></>

  const hasAccount = (accounts || []).length > 0
  if (hasAccount) {
    return (
      <Stack direction='row' spacing={1} alignItems='center'>
        <Iconify icon='eva:checkmark-circle-2-fill'
                 sx={{
                   height: ICON_SIZE,
                   width: ICON_SIZE,
                   color: 'success.darker',
                   background: '#FFF',
                   borderRadius: ICON_SIZE / 2,
                 }} />
        <Typography variant='small'>Connect your Google Drive account</Typography>
        <Typography
          variant='small'
          onClick={handleConnectClick}
          color='primary'
          sx={{ cursor: 'pointer' }}
        >Reconnect?</Typography>
      </Stack>
    )
  }

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Iconify icon='eva:question-mark-circle-outline'
               sx={{ height: ICON_SIZE, width: ICON_SIZE, color: 'inherit', borderRadius: ICON_SIZE / 2 }} />
      <Button
        variant='outlined'
        size='small'
        onClick={handleConnectClick}
        color='primary'
      >Connect your Google Drive account</Button>
    </Stack>
  )
}

function GrantAccessStep({ accounts, fileId, asset }: {
  accounts: Account[],
  fileId: string | undefined,
  asset: Asset
}) {
  const [wrongFileWarning, setWrongFileWarning] = useState<boolean>(false)
  const { data: accessInfo } = useGoogleDriveFileAccess(accounts[0]?.accountId, fileId)
  const { openPicker } = useDrivePicker()
  const queryClient = useQueryClient()

  // handlers
  const handlePickerResponse = (data: any) => {
    const result = data as google.picker.ResponseObject
    if (result[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
      const toScan = result[google.picker.Response.DOCUMENTS]
      const matchingFile = toScan.find(it => it[google.picker.Document.ID] == fileId)
      const selectedMatchingFile = Boolean(matchingFile)
      setWrongFileWarning(!selectedMatchingFile)
      if (selectedMatchingFile) {
        retryExtractionWorkflow(asset.assetId)
      }
    }
    queryClient.invalidateQueries(['google-drive-file-access'])
  }

  const handlePickerClick = () => {
    const accountId = accounts && accounts[0]?.accountId
    accountId && openPicker(accountId, { callback: handlePickerResponse })
  }

  // calculated props
  const hasAccount = accounts && accounts.length > 0
  const hasAccess = Boolean(accessInfo?.hasAccess)
  if (hasAccess) {
    return (
      <Stack direction='row' spacing={1} alignItems='center'>
        <Iconify icon='eva:checkmark-circle-2-fill'
                 sx={{
                   height: ICON_SIZE,
                   width: ICON_SIZE,
                   color: 'success.darker',
                   background: '#FFF',
                   borderRadius: ICON_SIZE / 2,
                 }} />
        <Typography variant='small'>Access has been granted to Masset</Typography>
      </Stack>
    )
  }

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Iconify icon='eva:question-mark-circle-outline'
                 sx={{ height: ICON_SIZE, width: ICON_SIZE, color: 'inherit', borderRadius: ICON_SIZE / 2 }} />
        <Button
          variant='outlined'
          size='small'
          color='primary'
          disabled={!hasAccount}
          onClick={handlePickerClick}
        >Grant Access To Masset</Button>
      </Stack>

      {wrongFileWarning && (
        <Alert severity='warning' variant='outlined'>
          <AlertTitle>No Access</AlertTitle>
          Hmmm... it looks like Masset still doesn't have access even after you selected the file. Are you sure you
          selected the right file?
        </Alert>
      )}
    </Stack>
  )
}