import { Container, Paper, Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { alpha, styled, useTheme } from '@mui/material/styles'
import { PoweredByMasset } from '../../components/hosted-content-room/PoweredByMasset'
import { MotionContainer, varBounce } from '../../components/animate'
import Page from '../../components/Page'
import Image from '../../components/Image'
import { motion } from 'framer-motion'

export function HostedContentRoomModalLayout({ children }: { children?: ReactNode }) {
  const theme = useTheme()
  return (
    <Page title='Content Room Not Found'>

      <Container component={MotionContainer}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35 }}
        >
          <ContentStyle>
            {children}
          </ContentStyle>
        </motion.div>
      </Container>

      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: 2,
          bgcolor: alpha(theme.palette.background.paper, .0),
          position: 'absolute',
          bottom: 24,
          left: 24,
          width: 400,
        }}
      >
        <PoweredByMasset />
      </Paper>

      <Image
        src='/assets/backgrounds/pcr_background.webp'
        sx={{
          opacity: .04,
          mixBlendMode: 'screen',
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
          zIndex: -1,
          pointerEvents: 'none',
        }}
      />

    </Page>
  )
}

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 425,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))
