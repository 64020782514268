import { Stack } from '@mui/material'
import React, { useContext } from 'react'
import { InternalFormShareItem } from './AssetShareField'
import { FilteredGroupMemberList } from './FilteredShareItemsList'
import { NotSharedList } from './NotSharedList'
import { AssetShareFieldContext } from './AssetShareFieldContext'

type SearchResultsProps = {
  items: InternalFormShareItem[],
}

export function SharingSearchResults({ items }: SearchResultsProps) {
  const { searchTerm } = useContext(AssetShareFieldContext)
  if (!Boolean(searchTerm)) {
    return <></>
  }
  return (
    <Stack>
      <FilteredGroupMemberList items={items} />
      <NotSharedList items={items}  />
    </Stack>
  )
}
