import api from '@api'
import {
  GetAssetDocumentSummaryResponse,
  GetAssetGeneratedQuestionsResponse, GetAssetImageSummaryResponse, GetAssetOCRContentsResponse,
  GetAssetTranscriptionResponse,
} from '../@types/asset-content'

export async function getAssetTranscription(assetId: string){
  const response = await api.get<GetAssetTranscriptionResponse>(`/api/v1/assets/${assetId}/content/transcription`)
  return response.data
}

export async function getAssetDocumentSummary(assetId: string){
  const response = await api.get<GetAssetDocumentSummaryResponse>(`/api/v1/assets/${assetId}/content/document-summary`)
  return response.data
}

export async function getAssetGeneratedQuestions(assetId: string){
  const response = await api.get<GetAssetGeneratedQuestionsResponse>(`/api/v1/assets/${assetId}/content/questions`)
  return response.data
}

export async function getAssetImageSummary(assetId: string){
  const response = await api.get<GetAssetImageSummaryResponse>(`/api/v1/assets/${assetId}/content/image-summary`)
  return response.data
}

export async function getAssetOCRContents(assetId: string){
  const response = await api.get<GetAssetOCRContentsResponse>(`/api/v1/assets/${assetId}/content/ocr-contents`)
  return response.data
}