import React, { useContext } from 'react'
import { AdminAnalyticsContext } from '../../contexts/AdminAnalyticsContext'
import Pagination from '../pagination/Pagination'
import { useAssetAnalytics } from '../../hooks/analytics/useAssetAnalytics'

export default function AssetAnalyticsPagination() {
  const { filters: { currentPage, pageSize }, setPage } = useContext(AdminAnalyticsContext)
  const { data } = useAssetAnalytics()
  const totalResultCount = data?.totalResultCount || 0
  const handlePageChange = (pageNum: number) => setPage(pageNum)
  return (
    <Pagination
      currentPage={currentPage}
      pageSize={pageSize}
      totalResultCount={totalResultCount}
      onPageChange={handlePageChange}
      scrollOnPageChange={true}
    />
  )
}