import { Button } from '@mui/material'

export function ChatButton(){
  return (
    <Button
      variant="outlined"
      sx={{whiteSpace: "nowrap"}}
    >
      Chat
    </Button>
  )
}