import { Asset } from "../../../../@types/asset";
import { AnalyticsInterval, TimeRange } from '../../../../@types/analytics/analytics-common'
import BucketedShares from "./share/BucketedShares";
import TopSharers from "./share/TopSharers";
import React from "react";
import { Grid } from "@mui/material";
import ShareRank from "./share/ShareRank";
import TotalShares from "./share/TotalShares";

export default function ShareMetricsAssetDetailsLayout({ asset, range }: { asset: Asset, range: TimeRange }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <TotalShares asset={asset} range={range} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <ShareRank asset={asset} range={range} />
      </Grid>
      <Grid item xs={12}>
        <BucketedShares asset={asset} range={range} />
      </Grid>
      <Grid item xs={12}>
        <TopSharers asset={asset} range={range} />
      </Grid>
    </Grid>
  );
}
