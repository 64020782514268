import { Asset } from "../../../../../@types/asset";
import { AnalyticsInterval, TimeRange } from '../../../../../@types/analytics/analytics-common'
import { useQuery } from "@tanstack/react-query";
import TotalWidget from "../common/TotalWidget";
import DownloadIllustration from "../../../../../assets/illustration_download";
import React from "react";
import { getTotalShares } from "../../../../../clients/AssetAnalyticShareClient";
import ShareIllustration from '../../../../../assets/illustration_share'
import { useAssetTotalShares } from '../../../../../hooks/analytics/useAssetShareAnalytics'

export default function TotalShares({ asset, range }: { asset: Asset, range: TimeRange }) {
  const { data, isLoading } = useAssetTotalShares(asset.assetId, { range })

  if(isLoading){
    return <></>
  }

  return (
    <TotalWidget
      title="Total Shares"
      total={data?.totalShares || 0}
      icon={<ShareIllustration />}
    />
  )
}