import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getBootstrap, login, logout } from '../clients/AuthClient'
import { history } from '../utils/history'
import { BootstrapResponse } from '../@types/auth'

export function useBootstrap(){
  return useQuery({
    queryKey: ['user-bootstrap'],
    queryFn: async () => getBootstrap(),
    refetchOnWindowFocus: 'always',
    staleTime: Infinity
  })
}

type LoginParams = {
  username: string,
  password: string
}

export function useLogin(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async({username, password}: LoginParams) => login(username, password),
    onSuccess: async data => {
      queryClient.invalidateQueries({ queryKey: ['user-bootstrap'] })
    }
  })
}

export function useLogout(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async() => logout(),
    onSuccess: async data => {
      queryClient.setQueryData(
        ['user-bootstrap'],
        () => ({} as BootstrapResponse),
      )
      history.replace("/login")
    }
  })
}