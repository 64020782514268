import * as React from 'react'
import { InputPoint, SparkbarChart } from '../chart/sparkline/SparkbarChart'

interface SparkGraphProps {
  color: string;
  data: InputPoint[];
}

// this doesn't support more complex hovering and such, but is much more performant.
// we can allow exploring through a drill in
export function SimpleSparkGraph({ color, data }: SparkGraphProps){
  return (
    <SparkbarChart width={150} height={30} barWidth={3} data={data} color={color}/>
  )
}