import { Timeline, timelineContentClasses, timelineItemClasses } from '@mui/lab'
import { Box, Button, Card, CardContent } from '@mui/material'
import React, { useState } from 'react'
import { useUserTimeline } from '../../../../hooks/analytics/useUserAnalytics'
import { TimelineEvent } from '../../../../@types/analytics/timeline-events'
import { UserTimelineEvent } from './UserTimelineEvent'
import { UserTimelineDialog } from './UserTimelineDialog'

export function UserTimeline({ userId }: { userId: string }) {
  const { data } = useUserTimeline(userId, 4)
  const [open, setOpen] = useState<boolean>(false)
  const handleSeeMore = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const topEvents = data?.events.slice(0, 4) || []
  return (
    <Card>
      <CardContent>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            [`& .${timelineContentClasses.root}`]: {
              paddingBottom: 1,
            },
          }}
        >
          {
            topEvents.map((it: TimelineEvent) => {
              return (
                <UserTimelineEvent key={it.createdAt + ''} event={it} />
              )
            })
          }
        </Timeline>
        <Box textAlign='center'>
          <Button variant='text' onClick={handleSeeMore}>See more events...</Button>
        </Box>
        <UserTimelineDialog userId={userId} open={open} onClose={handleDialogClose} />
      </CardContent>
    </Card>
  )
}
