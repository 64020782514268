import { Tabs } from '@mui/material'
import { Link } from 'react-router-dom'
import * as React from 'react'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import Iconify from '../../components/Iconify'
import NavVerticalTab from '../../components/nav-section/custom/NavVerticalTab'
import { SideBar } from '../../components/nav-section/custom/SideBar'

const deriveActiveTab = (path: string) => {
  if (path.includes("info")) {
    return "info"
  } else if (path.includes("appearance")) {
    return "appearance"
  } else if (path.includes("security")) {
    return "security"
  } else if (path.includes("integrations")) {
    return "integrations"
  }
  return false
}

export default function ProfileNavigation() {
  const { pathname } = useLocation()
  const tab = useMemo(() => deriveActiveTab(pathname), [pathname])
  return (
    <SideBar>
      <Tabs
        orientation="vertical"
        value={tab}
        TabIndicatorProps={{ sx: { display: "none" } }}
      >
        <NavVerticalTab
          icon={<Iconify icon="eva:person-outline"/>}
          label="Personal Info"
          value="info"
          component={Link}
          to="/profile/info"
        />
        <NavVerticalTab
          icon={<Iconify icon="eva:eye-outline"/>}
          label="Appearance"
          value="appearance"
          component={Link}
          to="/profile/appearance"
        />
        <NavVerticalTab
          icon={<Iconify icon="eva:shield-outline"/>}
          label="Security"
          value="security"
          component={Link}
          to="/profile/security"
        />
        <NavVerticalTab
          icon={<Iconify icon="ph:plug"/>}
          label="Integrations"
          value="integrations"
          component={Link}
          to="/profile/integrations"
        />
      </Tabs>
    </SideBar>
  )
}