import { useWatch } from 'react-hook-form'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import { RHFSelect } from '../../../hook-form'
import React from 'react'
import { GeneralAccessType, ShareLevel } from '../../../../@types/sharing'
import Iconify from '../../../Iconify'
import { useTheme } from '@mui/material/styles'

type GeneralAccessSelectorProps = {
  accessTypeName: string,
  accessLevelName: string
}

export function GeneralAccessSelector({ accessTypeName, accessLevelName }: GeneralAccessSelectorProps) {
  const theme = useTheme()
  const accessType = useWatch({ name: accessTypeName })
  const isRestricted = accessType == GeneralAccessType.RESTRICTED
  const icon = isRestricted ? 'eva:lock-outline' : 'ph:buildings'
  const backgroundColor = isRestricted ? theme.palette.bg.warmGrey2 : theme.palette.bg.mintDark
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Avatar sx={{ background: backgroundColor }}><Iconify icon={icon} /></Avatar>
        <Stack direction='column'>
          <Box>
            <RHFSelect
              name={accessTypeName}
              variant='standard'
              fullWidth={false}
              size='small'
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontWeight: 'bold',
                  'select:focus': {
                    backgroundColor: 'transparent !important',
                  },
                },
              }}
            >
              <option value={GeneralAccessType.RESTRICTED}>Restricted</option>
              <option value={GeneralAccessType.ORGANIZATION}>All Internal Users</option>
            </RHFSelect>
          </Box>
          <Box mt={-1}>
            {accessType == GeneralAccessType.RESTRICTED && (
              <Typography variant='small'>Only people with shared access can view the asset</Typography>
            )}
            {accessType == GeneralAccessType.ORGANIZATION && (
              <Typography variant='small'>All Masset users in your organization can access the asset</Typography>
            )}
          </Box>
        </Stack>
      </Stack>
      <Box>
        {
          !isRestricted && (
            <RHFSelect
              name={accessLevelName}
              size='small'
              inputProps={{
                sx: {
                  fontSize: 12,
                  lineHeight: 1,
                },
              }}
            >
              <option value={ShareLevel.EDIT}>Editor</option>
              <option value={ShareLevel.VIEW}>Viewer</option>
            </RHFSelect>
          )
        }

      </Box>

    </Stack>
  )
}