import { Asset } from '../../@types/asset'
import { AssetContentState } from '../../@types/asset-content'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { ReadinessContainer } from './ReadinessContainer'
import AssetLabelsCmp from '../asset-labels/AssetLabels'

export function LabelsReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  return (
    <ReadinessContainer
      icon="ph:tag"
      state={state}
      title="Object Detection"
      subtitle="Search images by what they include, not just their metadata"
    >
      <AssetLabelsCmp asset={asset} />
    </ReadinessContainer>
  )
}