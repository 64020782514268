import useAuth from '../../hooks/useAuth'
import Page from '../../components/Page'
import { Box, Button, Container, Divider, Link, Paper, Stack, Typography } from '@mui/material'
import LogoFull from '../../components/LogoFull'
import * as React from 'react'
import EmptyContent from '../../components/EmptyContent'
import Iconify from '../../components/Iconify'
import { useLogout } from '../../hooks/useBootstrap'
import { Company } from '../../@types/auth'


export function CompanySelect() {
  const { availableCompanies, user } = useAuth()
  const { mutateAsync: logout } = useLogout()
  const selectCompany = (companyId: string) => {
    localStorage.setItem("currentCompanyId", companyId)
  }

  return (
    <Page title="Login | Masset">
      <Container maxWidth="sm" sx={{ p: 8 }}>

        {/* center-aligned box*/}
        <Box sx={{ textAlign: "center" }}>
          <LogoFull sx={{ height: "32px", mb: 4, display: "inline-block" }}/>

          <Stack direction="column" spacing={1} mb={4}>
            <Typography variant="h1">Welcome to Masset!</Typography>
            <Typography>Choose a company to get back to work.</Typography>
          </Stack>
        </Box>


        <Paper elevation={2}>
          <Stack
            direction="row"
            sx={{ height: "48px", p: 2 }}
            alignItems="center"
            spacing={1}
          >
            <Typography>Companies for</Typography>
            <Typography variant="standardHighlight">{user?.email}</Typography>
          </Stack>
          <Divider/>


          {availableCompanies.map(function (it) {
            return (
              <CompanyRow
                key={it.companyId}
                company={it}
                selectCompany={selectCompany}
              />
            )
          })}

          {availableCompanies.length === 0 && <CompanySelectZeroState/>}
        </Paper>

        <Paper sx={{ mt: 6, p: 2 }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography> Looking to sign up a new company? </Typography>
            <Button
              disabled={true}
              variant="outlined"
            >
              Sign up (coming soon)
            </Button>
          </Stack>
        </Paper>

        <Stack mt={4} alignItems="center">
          <Typography variant="small">Not seeing the companies you expect?</Typography>
          <Link onClick={() => logout()} sx={{ cursor: "pointer" }}>
            <Typography variant="small">
              Try a different login
            </Typography>
          </Link>
        </Stack>

      </Container>
    </Page>
  )
}

function CompanySelectZeroState() {
  return (
    <EmptyContent
      title="No companies"
      description="You are not assigned to any companies. Reach out to an admin for an invitation."
    />
  )
}


function CompanyRow(
  {
    company,
    selectCompany
  }: {
    company: Company,
    selectCompany: (companyId: string) => void
  }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ p: 2, minHeight: "64px", cursor: "pointer" }}
      onClick={() => { selectCompany(company.companyId) }}
    >
      <Typography>{company.name}</Typography>
      <Iconify fontSize="32px" icon="eva:chevron-right-fill"/>
    </Stack>
  )
}