import React, { useState } from 'react'
import { Box, Button, Dialog, IconButton } from '@mui/material'
import Iconify from '../../Iconify'
import Typography from '@mui/material/Typography'
import { useAssetDocumentSummary } from '../../../hooks/useAssetContent'
import { Asset } from '../../../@types/asset'

interface ViewTranscriptPopoverProps {
  asset: Asset
}

export function ViewDocumentSummaryPopover({ asset }: ViewTranscriptPopoverProps) {
  const [open, setOpen] = useState(false)
  const { data: summary } = useAssetDocumentSummary(asset.assetId, open)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={handleOpen}
      >
        View Document Summary
      </Button>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth={true}
        onClose={handleClose}
      >

        <Box p={7}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Typography variant='h2' paragraph>Document Summary (Auto-Generated)</Typography>

          <Box
            sx={{
              maxHeight: 640,
              overflow: 'scroll',
              whitespace: 'pre-line',
            }}
          >

            <Typography
              variant='standard'
              color="text.deemphasized"
              sx={{ whiteSpace: "pre-line"}}
            >
              {summary?.summary}
            </Typography>

          </Box>
        </Box>
      </Dialog>
    </>
  )
}