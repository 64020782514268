import { Box, Divider, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useContext } from "react";
import { AssetUploadContext } from "../../contexts/AssetUploadContext";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { AssetUploadFinalizeMultipleFormProps } from "../../pages/AssetUploadFinalizeMultiple";
import { RHFSwitch, RHFTextField } from "../hook-form";

export default function BulkUploadAssetMetadata() {
  const { uploads } = useContext(AssetUploadContext)
  const { watch } = useFormContext<AssetUploadFinalizeMultipleFormProps>()
  const upload = uploads.find(it => !!it.asset)
  const asset = upload?.asset
  const [shouldBulkUpdateDescription, shouldBulkUpdateName] = watch(["shouldBulkUpdateDescription", "shouldBulkUpdateName"])

  return (
    <Box>
      <Typography variant="h3">Asset Info</Typography>

      <Stack
        mt={3}
        mb={8}
        direction="row"
        spacing={5}
      >
        <Box>
          <Typography component="div" variant="small" color="text.deemphasized">Uploaded:</Typography>
          <Typography variant="small" color="text.secondary">
            {asset?.createdAt && format(parseISO(asset?.createdAt), "MMM d, yyyy h:mm aa")}
          </Typography>
        </Box>
        <Box>
          <Typography component="div" variant="small" color="text.deemphasized">Uploaded by:</Typography>
          <Typography variant="small" color="text.secondary">
            {asset?.createdBy.firstName} {asset?.createdBy.lastName}
          </Typography>
        </Box>
      </Stack>

      <Typography variant="h3">Names and Descriptions</Typography>
      <Typography variant="small" color="text.deemphasized">You may chose batch names and descriptions or change names
        and descriptions individually after the batch upload.</Typography>
      <Divider sx={{ mt: 2, mb: 4 }}/>

      <RHFSwitch
        sx={{mb: 4}}
        name="shouldBulkUpdateName"
        label={<Box ml={2}>
          <Typography component="div" variant="standardHighlight">Batch name these assets</Typography>
          <Typography variant="small" color="text.secondary">If you do not use batch naming, assets will keep their current file names.</Typography>
        </Box>}
        labelPlacement="end"
      />

      { shouldBulkUpdateName && (
        <Box ml={8} mb={4}>
          <RHFTextField
            name="name"
            placeholder="Name"
            size="small"
            variant="filled"
          />
        </Box>
      )}

      <RHFSwitch
        sx={{mb:4}}
        name="shouldBulkUpdateDescription"
        label={<Box ml={2}>
          <Typography component="div" variant="standardHighlight">Use same description for all files</Typography>
          <Typography variant="small" color="text.secondary">If you do not use the same description for all files, those files won’t have a description.
            You can add descriptions individually to each file later.</Typography>
        </Box>}
        labelPlacement="end"
      />

      { shouldBulkUpdateDescription && (
        <Box ml={8} mb={4}>
          <RHFTextField
            name="description"
            placeholder="Description"
            multiline
            rows={4}
            size="small"
            variant="filled"
          />
        </Box>
      )}

    </Box>
  )
}