import { Asset } from '../../../../../@types/asset'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import TotalWidget from '../common/TotalWidget'
import DownloadIllustration from '../../../../../assets/illustration_download'
import React from 'react'
import { useAssetTotalDownloads } from '../../../../../hooks/analytics/useAssetDownloadAnalytics'

export default function TotalDownloads({ asset, range }: { asset: Asset, range: TimeRange }) {
  const { data, isLoading } = useAssetTotalDownloads(asset.assetId, { range })

  if(isLoading){
    return <></>
  }

  return (
    <TotalWidget
      title="Total Downloads"
      total={data?.totalDownloads || 0}
      icon={<DownloadIllustration />}
    />
  )
}