import { HostedContentRoomAsset } from '../../@types/hosted-content-room'
import { Masonry } from '@mui/lab'
import { ContentRoomAsset } from './asset/ContentRoomAsset'

type HorizontalAssetGroupProps = {
  assets: HostedContentRoomAsset[]
}


export function AssetMasonry({ assets }: HorizontalAssetGroupProps) {
  return (
    <Masonry
      columns={{xs: 1, sm: 2, md: 3, lg: 4, xl: 6}}
      spacing={5}
    >
      {
        assets.map((it, index) => {
          return (
            <ContentRoomAsset key={index} asset={it} />
          )
        })
      }
    </Masonry>
  )
}