import { AppBar, Box, Link, Stack, Toolbar, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'
import React from 'react'
import { CallToActionButton } from '../../components/hosted-content-room/CallToActionButton'
import { ChatButton } from '../../components/hosted-content-room/ChatButton'
import { HostedContentRoomSearch } from '../../components/hosted-content-room/HostedContentRoomSearch'
import { useHostedContentRoomBootstrap } from '../../hooks/useHostedContentRoom'
import LoadingScreen from '../../components/LoadingScreen'
import { CompanyLogo } from '../../components/hosted-content-room/CompanyLogo'
import { useParams } from 'react-router'
import { ContentRoomThemeProvider } from '../../components/hosted-content-room/ContentRoomThemeProvider'
import { useTheme } from '@mui/material/styles'
import { GetHostedContentRoomBootstrapResponse } from '../../@types/hosted-content-room'
import { PoweredByMasset } from '../../components/hosted-content-room/PoweredByMasset'

export function HostedContentRoomLayout() {
  const { shortCode } = useParams()
  const { data: contentRoom } = useHostedContentRoomBootstrap(shortCode)

  if (!contentRoom?.customization) {
    return <LoadingScreen />
  }

  return (
    <ContentRoomThemeProvider palette={contentRoom.customization.palette}>
      <AppBar position='fixed' elevation={0}>
        <Toolbar
          sx={{
            height: 1,
            px: '80px !important',
          }}
        >
          <Stack direction='row' spacing={2} alignItems='center' sx={{ width: '100%' }}>
            <CompanyLogo settings={contentRoom.customization.logo} />
            <HostedContentRoomSearch />
            <Box sx={{ flex: 1 }} />
            <ChatButton />
            <CallToActionButton />
          </Stack>
        </Toolbar>
      </AppBar>

      <LayoutContent contentRoom={contentRoom} />

    </ContentRoomThemeProvider>
  )
}

function LayoutContent({contentRoom} : {contentRoom: GetHostedContentRoomBootstrapResponse}){
  const theme = useTheme()
  return (
    <Box px={10} py={10} sx={{background: theme.palette.background.default}}>
      <Outlet />
      <Box mt={6} sx={{maxWidth: 450}}>
        <PoweredByMasset companyName={contentRoom.companyName} />
      </Box>
    </Box>
  )
}

