import AssetHeader from '../asset/AssetHeader'
import { Box, Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import AdminNavigation from './AdminNavigation'
import { AdminUsersProvider } from '../../contexts/AdminUsersContext'


export default function AdminLayout() {
  return (
    <AdminUsersProvider>
      <Container sx={{ p: 4 }}>
        <AssetHeader />
        <Box sx={{ mt: 4, display: 'flex', height: 1 }}>
          {/* for now, we don't show admin navigation */}
          <AdminNavigation />
          <Box sx={{ flex: 1 }}>
            <Outlet />
          </Box>
        </Box>
      </Container>
    </AdminUsersProvider>
  )
}