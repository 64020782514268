import { Asset } from '../../../@types/asset'
import { AssetContentState, AssetContentStateErrorCode } from '../../../@types/asset-content'
import { RawFileGenericError } from './errors/RawFileGenericError'
import { RawFileGoogleDocPrivateLinkError } from './errors/RawFileGoogleDocPrivateLinkError'

export function RawFileErrorFeedback({ asset, state }: { asset: Asset, state: AssetContentState }) {
  if (state.errorCode == AssetContentStateErrorCode.GOOGLE_DOC_PRIVATE_LINK_FORBIDDEN ||
      state.errorCode == AssetContentStateErrorCode.GOOGLE_DOC_AUTHENTICATED_NOT_FOUND ||
      state.errorCode == AssetContentStateErrorCode.GOOGLE_DOC_AUTHENTICATED_NO_VALID_ACCOUNT
  ) {
    return <RawFileGoogleDocPrivateLinkError asset={asset} state={state} />
  }

  // generic unknown error
  return (
    <RawFileGenericError asset={asset} state={state} />
  )
}