import { Asset } from '../../../@types/asset'
import { Box, Typography } from '@mui/material'
import { useAssetImageSummary } from '../../../hooks/useAssetContent'
import { fDateTime } from '../../../utils/formatTime'
import { useTheme } from '@mui/material/styles'

interface ImageSummaryContentsProps {
  asset: Asset
}

export function ImageSummaryContents({ asset }: ImageSummaryContentsProps) {
  const theme = useTheme()
  const { data: summary } = useAssetImageSummary(asset.assetId)
  return (
    <Box mt={3}
         sx={{
           pl: 2,
           borderLeft: `4px solid ${theme.palette.primary.darker}`
         }}
    >
      <Typography variant="standardHighlight" component="div">Summary (Auto-Generated)</Typography>
      { summary && (<Typography variant="small" component="div" sx={{mb:1}}>
        Generated on {fDateTime(summary.createdAt)}
      </Typography>)}
      <Typography variant="small" >{summary?.summary}</Typography>
    </Box>
  )
}