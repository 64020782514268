import { Asset } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { ImageSummaryContents } from './ImageSummaryContents'

export function ImageSummaryReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  return (
    <ReadinessContainer
      icon="eva:image-outline"
      state={state}
      title="Image Summary"
      subtitle="Help users and prospects to understand visual assets at a glance. This also aids in helping individuals to search by what an image is showing!"
    >
      {isReady && (<ImageSummaryContents asset={asset}/>)}
    </ReadinessContainer>
  )
}