import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import { Asset } from '../../../../../@types/asset'
import RankWidget from '../common/RankWidget'
import React from 'react'
import { useDownloadRank } from '../../../../../hooks/analytics/useAssetDownloadAnalytics'

export default function DownloadRank({ asset, range }: { asset: Asset, range: TimeRange }) {
  const { data, isLoading } = useDownloadRank(asset.assetId, { range })

  if (isLoading) {
    return <></>;
  }

  return (
    <RankWidget rank={data?.rank || 0} total={data?.maxRank || 0} />
  );
}