import React, { useContext } from 'react'
import { SearchContext } from '../../contexts/SearchContext'
import Pagination from '../pagination/Pagination'

type Props = {
  scrollOnPageChange?: boolean
}
export default function SearchResultsPagination({ scrollOnPageChange = false }: Props) {
  const { filters: { currentPage, pageSize }, totalResultCount, setPage } = useContext(SearchContext);
  const handlePageChange = (pageNum: number) => setPage(pageNum)
  return (
    <Pagination
      currentPage={currentPage}
      pageSize={pageSize}
      totalResultCount={totalResultCount}
      onPageChange={handlePageChange}
      scrollOnPageChange={scrollOnPageChange}
    />
  );
}