import { Asset } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { RawFileErrorFeedback } from './RawFileErrorFeedback'
import { Divider } from '@mui/material'

export function RawFileReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const isError = state.status == AssetContentStateStatus.FAILED
  return (
    <ReadinessContainer
      icon="eva:file-outline"
      state={state}
      title="File Access"
      subtitle="Does Masset have access to the original file in order to run analysis? Without access, its hard to do other analysis!"
    >
      {isError && (
        <>
          <Divider sx={{mb:2}}/>
          <RawFileErrorFeedback asset={asset} state={state} />
        </>

      )}
    </ReadinessContainer>
  )
}