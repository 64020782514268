import { Box, Card, CardProps, Stack, Tooltip, Typography } from "@mui/material";
import { fShortenNumber } from "../../../../../utils/formatNumber";
import Iconify from "../../../../Iconify";
import React from "react";

interface RankProps extends Omit<CardProps, "title"> {
  rank: number;
  total: number;
}
export default function RankWidget({ rank, total, sx, ...other }: RankProps) {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        pl: 3,
        ...sx
      }}
      {...other}
    >
      <Box>
        <Stack direction="row" alignItems="baseline">
          <Box sx={{ mb: 1, typography: "h2" }}>{rank}&nbsp;</Box>
          <Box sx={{ color: "text.deemphasized", typography: "smallHighlight" }}>/ {fShortenNumber(total)}</Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography variant="smallHighlight" color="text.secondary">Rank&nbsp;</Typography>
          <Tooltip
            title="Rank describes how this asset compares to other assets inside of Masset."
            sx={{display: "flex"}}
          >
            <Box>
              <Iconify icon="eva:question-mark-circle-outline" width={16} height={16} />
            </Box>
          </Tooltip>
        </Stack>
      </Box>

      <Box
        sx={{
          width: 64,
          height: 64,
          lineHeight: 0,
          borderRadius: "50%",
          bgcolor: "background.neutral"
        }}
      >
        <Iconify icon="solar:medal-ribbons-star-bold-duotone" color="#F7BA00" width={64} height={64} />
      </Box>
    </Card>
  );
}