import { Asset } from "../../../../../@types/asset";
import { AnalyticsInterval, TimeRange } from '../../../../../@types/analytics/analytics-common'
import { useQuery } from "@tanstack/react-query";
import RankWidget from "../common/RankWidget";
import React from "react";
import { getShareRank } from "../../../../../clients/AssetAnalyticShareClient";
import { useShareRank } from '../../../../../hooks/analytics/useAssetShareAnalytics'

export default function ShareRank({ asset, range }: { asset: Asset, range: TimeRange }) {
  const { data, isLoading } = useShareRank(asset.assetId, { range })

  if (isLoading) {
    return <></>;
  }

  return (
    <RankWidget rank={data?.rank || 0} total={data?.maxRank || 0} />
  );
}