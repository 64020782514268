import { Asset, isTaskStatusRunning, TaskStatus } from '../../../@types/asset'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import { ContentGPSRings } from './ContentGPSRings'
import { useContentGPS, useRetryContentGPSMutation } from '../../../hooks/useAsset'


export function AssetDetailContentGPSTab({ asset }: { asset: Asset }) {
  // hooks
  const { data: gps, isError, isLoading: fetching } = useContentGPS(asset.assetId)
  const { mutate: retryContentGPS, isLoading: retrying } = useRetryContentGPSMutation()

  // handlers
  const handleRetryCalculation = () => retryContentGPS(asset.assetId)

  // calculated props
  const isLoading = fetching || retrying

  if (isLoading) {
    return <CircularProgress />
  }

  if (gps?.assetResults.status === TaskStatus.FAILED || isError) {
    return <Failed assetId={asset.assetId} retryCalculation={handleRetryCalculation}/>
  }

  if (gps?.assetResults.status === TaskStatus.MISSING) {
    return <Missing assetId={asset.assetId} retryCalculation={handleRetryCalculation}/>
  }

  if (isTaskStatusRunning(gps?.assetResults.status)) {
    return <InProcess assetId={asset.assetId} />
  }

  if (!gps) {
    return <></>
  }

  return (
    <Stack direction='column' spacing={4}>
      <ContentGPSRings gps={gps} />
      {/*<ContentGPSSimilarImages gps={gps}/>*/}
    </Stack>
  )
}

function Failed({ assetId, retryCalculation }: { assetId: string, retryCalculation: () => void }) {
  return (
    <Stack direction='column' spacing={1}>
      <Typography variant='small' color='text.deemphasized'>Hmmm... it looks like something went wrong last time we ran
        Content GPS</Typography>
      <Typography
        variant='small'
        color='text.mint'
        sx={{ cursor: 'pointer' }}
        onClick={() => retryCalculation()}>
        Click here to try again
      </Typography>
    </Stack>
  )
}

function Missing({ assetId, retryCalculation }: { assetId: string, retryCalculation: () => void }) {
  return (
    <Box>
      <Stack direction='column' spacing={1}>
        <Typography variant='small' color='text.deemphasized'>It looks like we've never run Content GPS on this
          asset.</Typography>
        <Typography
          variant='small'
          color='text.mint'
          sx={{ cursor: 'pointer' }}
          onClick={() => retryCalculation()}>
          Click here to see how this asset stacks up!
        </Typography>
      </Stack>
    </Box>
  )
}

function InProcess({ assetId }: { assetId: string }) {
  return (
    <Box>
      <Typography variant='h6' mb={2}>Asset Exposure</Typography>
      <Stack direction='row' spacing={1}>
        <CircularProgress size={12} />
        <Typography variant='small' color='text.deemphasized'>Analyzing... your results should be available soon.</Typography>
      </Stack>
    </Box>
  )
}

