import { useCategoryValuesWithCategories } from '../../hooks/useCategories'
import { useFormContext, useWatch } from 'react-hook-form'
import { Box, Button, Popover } from '@mui/material'
import Iconify from '../Iconify'
import React, { useState } from 'react'
import { ReadOnlyTag } from '../tags/ReadOnlyTag'
import { TagPickerMenu } from '../analytics/TagPickerMenu'
import { CategoryValueFilterMode } from '../../@types/analytics/analytics-asset'

export function RHFTagsField({ name }: { name: string }) {
  const categoryValuesWithCategories = useCategoryValuesWithCategories()
  const { setValue } = useFormContext()
  const categoryValueIds = useWatch({ name }) as string[]
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  // handlers
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleOnRemove = (toRemove: string) => {
    const newValue = categoryValueIds.filter(it => it !== toRemove)
    setValue(name, newValue, { shouldDirty: true })
  }
  const handleApply = (selectedIds: string[]) => {
    setValue(name, selectedIds, { shouldDirty: true })
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? `tags-field-popover-${name}` : undefined

  return (
    <Box>
      {categoryValueIds.map(id => {
        const pair = categoryValuesWithCategories[id]
        if (!pair) return null
        return (
          <ReadOnlyTag
            key={pair.categoryValue.categoryValueId}
            categoryValue={pair.categoryValue}
            category={pair.category}
            onRemove={handleOnRemove}
          />

        )
      })}

      <Button
        color='inherit'
        disableRipple
        size='small'
        variant='outlined'
        onClick={handleOpen}
        startIcon={<Iconify icon={'eva:edit-outline'} />}
      >
        Modify Tags
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -4, horizontal: 0 }}
      >
        <TagPickerMenu
          initialSelectedIds={categoryValueIds}
          initialMode={CategoryValueFilterMode.ALL_OF}
          onCancel={handleClose}
          onApply={handleApply}
          disableModeSelection={true}
        />
      </Popover>

    </Box>
  )
}