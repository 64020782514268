import { Box, Link, Stack, Typography } from '@mui/material'
import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room'
import { alpha, useTheme } from '@mui/material/styles'
import Image from '../../../Image'
import React from 'react'
import { useParams } from 'react-router'

type FadeToBlackOverlaidImageCardProps = {
  asset: HostedContentRoomAsset
}

export function FadeToBlackOverlaidImageCard({ asset }: FadeToBlackOverlaidImageCardProps) {
  const { shortCode } = useParams()
  const theme = useTheme()

  return (
    <Box sx={{
      position: 'relative',
    }}>

      <Stack
        spacing={1}
        sx={{
          p: 3,
          width: 1,
          bottom: 0,
          zIndex: 9,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
        }}
      >

        <Typography variant='overline' sx={{ color: 'primary.light' }}>
          Featured Content
        </Typography>

        <Typography variant='h5' noWrap>
          {asset.name}
        </Typography>

        { asset.description && (<Typography variant='body2' noWrap>
          {asset.description}
        </Typography>) }
      </Stack>

      <Image
        alt={asset.name}
        src={`/api/v1/cr/${shortCode}/assets/${asset.assetId}/previews/${asset.assetPreviewId}`}
        overlay={`linear-gradient(to bottom, ${alpha(theme.palette.grey[900], 0)} 0%, ${
          theme.palette.grey[900]
        } 75%)`}
        sx={{
          width: 1,
        }}
      />
    </Box>

  )
}
