import { Box, Button, Card, CardHeader, Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import orderBy from 'lodash/orderBy'
import Iconify from '../../../../Iconify'
import { fShortenNumber } from '../../../../../utils/formatNumber'
import { alpha } from '@mui/material/styles'
import Image from '../../../../Image'

export const NO_LIMIT = 9999

export type UserListItem = {
  userId: string,
  firstName: string,
  lastName: string,
  metricValue: number,
  metricLabel?: string,
  metricIcon?: string
}

interface Props {
  title: ReactNode;
  subheader?: ReactNode;
  list?: UserListItem[];
  moreResults?: boolean;
  onSeeAllClick?: () => void
}

export default function TopUsers({ title, subheader, list, moreResults, onSeeAllClick }: Props) {
  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />
      <Stack spacing={1} sx={{ p: 2 }}>
        {
          orderBy(list, ['metricValue'], ['desc']).map((it, idx) => {
            return (
              <UserItem key={it.userId} userItem={it} index={idx} />
            )
          })
        }
        {
          list && !Boolean(list.length) && (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Image
                disabledEffect
                visibleByDefault
                alt='empty content'
                src='/assets/illustrations/illustration_empty_content.svg'
                sx={{ height: 48, my: 1 }}
              />
              <Typography >No Results</Typography>
            </Stack>
          )
        }
        {moreResults && (
          <Button
            fullWidth
            variant='text'
            onClick={() => onSeeAllClick && onSeeAllClick()}
          >
            See all
          </Button>
        )}
      </Stack>
    </Card>
  )
}

function UserItem({ userItem, index }: { userItem: UserListItem, index: number }) {
  const name = `${userItem.firstName} ${userItem.lastName}`
  const metricIcon = userItem.metricIcon
  const metricLabel = userItem.metricLabel
  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      <PlaceIcon place={index + 1} />

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='subtitle2'>{name}</Typography>
      </Box>

      <Typography
        variant='caption'
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'text.secondary',
        }}
      >
        {metricIcon && <Iconify icon={metricIcon} sx={{ width: 16, height: 16, mr: 0.5 }} />}
        {fShortenNumber(userItem.metricValue)}&nbsp;{metricLabel && metricLabel}
      </Typography>

    </Stack>
  )
}

const DEFAULT_TROPHY_COLOR = '#555'
const TROPHY_COLORS = {
  1: '#F7BA00',
  2: '#D5D5D5',
  3: '#CD7F32',
} as { [key: number]: string }

function PlaceIcon({ place }: { place: number }) {
  const color = TROPHY_COLORS[place] || DEFAULT_TROPHY_COLOR
  return (
    <Iconify
      icon='solar:cup-star-bold'
      sx={{
        p: 1,
        width: 32,
        height: 32,
        borderRadius: '50%',
        color: color,
        bgcolor: alpha(color, 0.08),
      }}
    />
  )
}