import { useParams } from 'react-router'
import Page from '../../components/Page'
import { Alert, IconButton, InputAdornment, Paper, Stack, Typography } from '@mui/material'
import { useHostedContentRoomLogin } from '../../hooks/useHostedContentRoom'
import * as React from 'react'
import { useState } from 'react'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, RHFTextField } from '../../components/hook-form'
import Iconify from '../../components/Iconify'
import { LoadingButton } from '@mui/lab'
import LogoFull from '../../components/LogoFull'
import { HostedContentRoomModalLayout } from '../../layouts/hosted-content-room/HostedContentRoomModalLayout'


export function HostedContentRoomLogin() {
  const { shortCode } = useParams()
  const { mutateAsync: login } = useHostedContentRoomLogin()
  const [showPassword, setShowPassword] = useState(false)

  // setup the form
  const methods = useForm<FormValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })
  const { reset, setError, handleSubmit, formState: { errors, isSubmitting } } = methods

  // handler for login action
  const onSubmit = async (data: FormValues) => {
    if (!shortCode) return
    try {
      await login({ shortCode, password: data.password })
    } catch (error) {
      reset()
      setError('afterSubmit', { ...error, message: 'Invalid password. Please try again.' })
    }
  }

  return (
    <HostedContentRoomModalLayout>
      <Page title='Login'>
        <Paper elevation={4} sx={{ p: 4, zIndex: 10 }}>
          {/* todo: include/swap for company logo */}
          <LogoFull sx={{ mb: 4, px: 8 }} />

          <FormProvider methods={methods}>
            <Stack spacing={4}>
              <Typography variant='h3' align='center'>
                Sign in to your Content Room
              </Typography>

              {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit.message}</Alert>}

              <RHFTextField
                name='password'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <LoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </LoadingButton>

            </Stack>

          </FormProvider>
        </Paper>
      </Page>
    </HostedContentRoomModalLayout>
  )
}

type FormValues = {
  password: string;
  afterSubmit?: string;
};
const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
})
const defaultValues = { password: '' }
