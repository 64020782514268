import { AnalyticsUserResult } from '../../../@types/analytics/analytics-user'
import { PLACEHOLDER_USER_SUMMARIES, useUserAnalyticSummaries } from '../../../hooks/analytics/useUserAnalytics'
import { styled, useTheme } from '@mui/material/styles'
import { Avatar, Collapse, Skeleton, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { SimpleSparkGraph } from '../AnalyticsSparkGraph'
import { fNumber } from '../../../utils/formatNumber'
import React, { useState } from 'react'
import { StripedTableRow } from '../common/StripedTableRow'
import { UserAnalyticsTableRowDetails } from './details/UserAnalyticsTableRowDetails'
import Iconify from '../../Iconify'
import { fShortDate, fTime } from '../../../utils/formatTime'

export function AnalyticsUserRow({ user, rowNum }: { user: AnalyticsUserResult, rowNum: number }) {
  // hooks
  const { data = PLACEHOLDER_USER_SUMMARIES } = useUserAnalyticSummaries()
  const theme = useTheme()
  const [expanded, setExpanded] = useState<boolean>(false)

  // handlers
  const handleExpand = () => setExpanded(!expanded)

  // calculated
  const { summaries } = data
  const isStriped = rowNum % 2 == 0
  const iconRotation = expanded ? '90deg' : '0deg'
  const chartData = summaries[user.userId]?.entries
      .map(it => ({ value: it.count, startDate: it.bucketStart, endDate: it.bucketEnd }))
    || generateDefaultChartData()

  return (
    <>
      <StripedTableRow isStriped={isStriped}>
        <TableCell sx={{ py: 1.5 }}>
          <Stack direction='row' spacing={2} alignItems='center' sx={{ height: 48 }}>
            <Iconify
              onClick={handleExpand}
              icon='eva:chevron-right-fill'
              sx={{
                fontSize: 20,
                transition: '100ms all ease-in-out',
                rotate: iconRotation,
                cursor: 'pointer',
              }}
            />
            <Avatar sx={{ height: 32, width: 32, bgcolor: theme.palette.bg.mintDark }}>{user.name[0]}</Avatar>
            <Typography>{user.name}</Typography>
          </Stack>
        </TableCell>

        <TableCell>
          <SimpleSparkGraph color={theme.palette.chart.green[0]} data={chartData} />
        </TableCell>
        <TableCell>
          {
            user.lastSessionAt ? (
              <Stack direction="column">
                <Typography variant='smallHighlight'>{fTime(user.lastSessionAt)}</Typography>
                <Typography variant='small'>{fShortDate(user.lastSessionAt)}</Typography>
              </Stack>
            ) : (
              <Typography variant='small'>Never</Typography>
            )
          }
        </TableCell>
        <NumberTableCell>
          <Typography variant='small'>{fNumber(user.numFavorites)}</Typography>
        </NumberTableCell>
        <NumberTableCell>
          <Typography variant='small'>{fNumber(user.numDownloads)}</Typography>
        </NumberTableCell>
        <NumberTableCell>
          <Typography variant='small'>{fNumber(user.numShares)}</Typography>
        </NumberTableCell>
        <NumberTableCell>
          <Typography variant='small'>{fNumber(user.numViews)}</Typography>
        </NumberTableCell>
      </StripedTableRow>

      {
        expanded && (
          <StripedTableRow isStriped={isStriped}>
            <TableCell colSpan={7}>
              <Collapse in={expanded} timeout='auto' unmountOnExit>
                <UserAnalyticsTableRowDetails user={user} />
              </Collapse>
            </TableCell>
          </StripedTableRow>
        )
      }
    </>

  )
}

const NumberTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'right',
  paddingRight: 8,
}))

function generateDefaultChartData() {
  return Array(30).fill(0).map(it => ({
    value: it,
  }))
}


export function UserSkeletonTableRow({ rowNum }: { rowNum: number }) {
  const isStriped = rowNum % 2 == 0
  return (
    <StripedTableRow isStriped={isStriped}>
      <TableCell sx={{ py: 1.5, height: 72 }}>
        <Stack direction='row' spacing={2} alignItems='center' sx={{ height: 48 }}>
          <Iconify
            icon='eva:chevron-right-fill'
            sx={{
              fontSize: 20,
              transition: '100ms all ease-in-out',
              cursor: 'pointer',
            }}
          />
          <Skeleton variant='circular' sx={{ width: 32, height: 32 }} />
          <Typography variant='smallHighlight' mb={1} component='div'>
            <Skeleton variant='text' sx={{ width: 240, height: 24 }} />
          </Typography>
        </Stack>
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Skeleton variant='text' sx={{ width: 60 }} />
      </TableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} component={Stack} />
        </Stack>
      </NumberTableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} />
        </Stack>
      </NumberTableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} />
        </Stack>
      </NumberTableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} />
        </Stack>
      </NumberTableCell>
    </StripedTableRow>
  )
}
