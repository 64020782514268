import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import { Asset } from '../../../@types/asset'
import React, { useContext, useState } from 'react'
import Iconify from '../../Iconify'
import { AssetShareInternalTab } from './internal/AssetShareInternalTab'
import { DialogContext, DialogProvider } from '../../../contexts/DialogContext'

type AssetShareButtonProps = { asset: Asset }

export function AssetShareButtonV2({ asset }: AssetShareButtonProps) {
  return (
    <DialogProvider>
      <TriggerButton />
      <ShareDialog asset={asset} />
    </DialogProvider>
  )
}

function TriggerButton() {
  const { setOpen } = useContext(DialogContext)
  const handleOpen = () => setOpen(true)

  return (
    <Button
      variant='outlined'
      size='small'
      fullWidth
      onClick={handleOpen}
      startIcon={<Iconify icon='eva:share-outline' />}
    >
      Share
    </Button>
  )
}

function ShareDialog({ asset }: { asset: Asset }) {
  const { open, setOpen } = useContext(DialogContext)
  const [selectedTab, setSelectedTab] = useState<string>('internal')

  // handle the form submission
  const handleClose = () => setOpen(false)
  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => setSelectedTab(newValue)
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth={true}
      onClose={handleClose}
    >

      <Box>
        <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
          <IconButton onClick={handleClose}>
            <Iconify icon='eva:close-fill' />
          </IconButton>
        </Box>

        <Typography variant='h2' px={5} mt={7} mb={4}>Share "{asset.name}"</Typography>

        {/* for now, we just have internal sharing */}
        <AssetShareInternalTab asset={asset} />


        {/*<TabContext value={selectedTab}>*/}
        {/*  <TabList*/}
        {/*    onChange={handleTabChange}*/}
        {/*    variant='scrollable'*/}
        {/*    scrollButtons='auto'*/}
        {/*    sx={{ mt: 2 }}*/}
        {/*  >*/}
        {/*    <Tab label='Internal' value='internal' />*/}
        {/*    <Tab label='Personal Content Rooms' value='rooms' />*/}
        {/*  </TabList>*/}

        {/*  <Divider sx={{ mb: 2 }} />*/}

        {/*  <TabPanel value='internal'>*/}
        {/*  </TabPanel>*/}

        {/*  <TabPanel value='rooms'>*/}
        {/*    <AssetSharePersonalContentRoomsTab />*/}
        {/*  </TabPanel>*/}
        {/*</TabContext>*/}
      </Box>
    </Dialog>
  )
}