import { Account } from '../../../../@types/account'
import { Box, Breadcrumbs, Button, Link, List, ListItem, Paper, Stack, Typography } from '@mui/material'
import { fDate } from '../../../../utils/formatTime'
import { IntegrationLogo } from '../IntegrationLogo'
import React from 'react'
import Iconify from '../../../Iconify'
import { useTheme } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import { GoogleDrivePermittedFiles } from './google-drive/GoogleDrivePermittedFiles'
import { useOauthPopup } from '../../../../hooks/useOauth'
import { useSnackbar } from 'notistack'
import { IntegrationAccountDeleteButton } from '../IntegrationAccountDeleteButton'

export function GoogleDriveIntegrationDetails({ account }: { account: Account }) {
  const { enqueueSnackbar } = useSnackbar()
  const handleDelete = () => { }
  const handleConnectClick = () => trigger('google-drive')
  const handleOauthSuccess = () => enqueueSnackbar(
    <Stack spacing={1} direction="row" alignItems="center">
      <Typography variant="smallHighlight">Booyah!</Typography>
      <Typography component="div" variant="small" noWrap>Your account has been reconnected!</Typography>
    </Stack>
  )
  const { trigger } = useOauthPopup({ onSuccess: handleOauthSuccess })

  return (
    <Stack spacing={4}>

      <Breadcrumbs>
        <Link
          variant='small'
          component={RouterLink} to='/profile/integrations' style={{ textDecoration: 'none' }}
        >
          Integrations
        </Link>
        <Typography variant='small'>Google Drive</Typography>
      </Breadcrumbs>

      {/* header */}
      <Stack direction='row' spacing={1}>
        <IntegrationLogo provider={account.provider} />
        <Box sx={{ flex: 1 }}>
          <Typography variant='h2'>Google Drive</Typography>
          <Typography variant='standard' paragraph color='text.deemphasized'>Added
            on {fDate(account.createdAt)}</Typography>
        </Box>

        <Stack direction='row' spacing={2}>
          <Box>
            <IntegrationAccountDeleteButton accountId={account.accountId} />
          </Box>
        </Stack>
      </Stack>

      {/* description */}

      {/* authentication */}
      <Paper elevation={1} sx={{ p: 4 }}>
        <Typography variant='h2' paragraph>Authentication</Typography>
        <Stack direction='row'>
          <Box sx={{ flex: 1 }}>
            <Typography variant='standard'>
              The Google Drive integration can be installed for your company's Masset instance, but must be configured
              for each user who wants to import Google Drive files into Masset.
            </Typography>
          </Box>
          <Stack spacing={2} flex={1}>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <AccountPlacard account={account} />
            </Box>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <Button
                size='small'
                variant='outlined'
                onClick={handleConnectClick}
              >
                Reconnect?
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Paper>

      <GoogleDrivePermittedFiles account={account} />


      {/* usage guide */}
      <Paper elevation={1} sx={{ p: 4 }}>
        <Typography variant='h2' paragraph>Usage Guide</Typography>
        <Typography variant='standard'>
          Use the Google Drive inside of Masset to:
          <List sx={{
            listStyleType: 'disc',
            listStylePosition: 'inside',
          }}>
            <ListItem sx={{ display: 'list-item' }}>Directly import Google Drive files as assets</ListItem>
            <ListItem sx={{ display: 'list-item' }}>Link to private Google Drive files and allow Masset
              access</ListItem>
            <ListItem sx={{ display: 'list-item' }}> Generate previews of private Google Drive files</ListItem>
            <ListItem sx={{ display: 'list-item' }}>Sync Google Drive file updates into Masset</ListItem>
          </List>
        </Typography>
      </Paper>


    </Stack>
  )
}

function AccountPlacard({ account }: { account: Account }) {
  const theme = useTheme()
  return (
    <Paper sx={{
      background: theme.palette.background.neutral,
      display: 'inline-block',
      py: 2,
      px: 3,
    }}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        spacing={2}
        sx={{
          // border: `1px solid ${theme.palette.primary.dark}`,
          borderRadius: 1,
        }}
      >
        <Iconify icon='ph:user-circle-check-fill'
                 sx={{ color: 'success.dark', width: 48, height: 48, background: '#fff', borderRadius: 24 }} />
        <Box>
          <Typography variant='standard' component='div'>Successfully connected!</Typography>
          <Typography variant='h3' component='div'>{account.name}</Typography>
          <Typography variant='small' component='div'>{fDate(account.updatedAt)}</Typography>
          <Typography></Typography>
        </Box>

      </Stack>
    </Paper>
  )
}
