import { Stack, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { AdminAnalyticsSortBy } from '../../../@types/analytics/analytics-asset'
import { AnalyticsSortableTableCell } from '../AnalyticsSortableTableCell'
import Iconify from '../../Iconify'
import { AnalyticsGraphTypeSelect } from '../AnalyticsGraphTypeSelect'
import { UserAnalyticsContext } from '../../../contexts/UserAnalyticsContext'
import { PLACEHOLDER_USER_ANALYTICS, useUserAnalytics } from '../../../hooks/analytics/useUserAnalytics'

export default function UserAnalyticsTableHead() {
  const {
    filters: { sortBy, sortDirection },
    summaryGraphType,
    setSort,
    setSummaryGraphType,
  } = useContext(UserAnalyticsContext)
  const { data } = useUserAnalytics()
  const {
    totalViews,
    totalShares,
    totalFavorites,
    totalDownloads,
  } = data || PLACEHOLDER_USER_ANALYTICS

  return (
    <TableHead>
      <TableRow>
        {/* avatar cell */}
        {/* name / tags */}
        <TableCell width='100%'></TableCell>

        {/* spark line*/}
        <TableCell sx={{ minWidth: 170 }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant='tiny'>Graph:</Typography>
            <AnalyticsGraphTypeSelect type={summaryGraphType} onTypeChange={setSummaryGraphType} />
          </Stack>
        </TableCell>

        <AnalyticsSortableTableCell
          label='Last Login'
          col={AdminAnalyticsSortBy.LAST_SESSION_DATE}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
          numeric={false}
          sx={{minWidth: 100}}
        />

        <AnalyticsSortableTableCell
          label='Favorites'
          col={AdminAnalyticsSortBy.NUM_FAVORITES}
          icon={<Iconify icon='eva:star-outline' />}
          total={totalFavorites}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />

        <AnalyticsSortableTableCell
          label='Downloads'
          col={AdminAnalyticsSortBy.NUM_DOWNLOADS}
          icon={<Iconify icon='eva:download-outline' />}
          total={totalDownloads}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />

        <AnalyticsSortableTableCell
          label='Shares'
          col={AdminAnalyticsSortBy.NUM_SHARES}
          icon={<Iconify icon='eva:share-outline' />}
          total={totalShares}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />

        <AnalyticsSortableTableCell
          label='Views'
          col={AdminAnalyticsSortBy.NUM_VIEWS}
          icon={<Iconify icon='eva:eye-outline' />}
          total={totalViews}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />
      </TableRow>
    </TableHead>
  )
}


