import { HostedContentRoomAsset } from '../../../@types/hosted-content-room'
import { fShortenNumber } from '../../../utils/formatNumber'
import { Stack, SxProps } from '@mui/material'
import Iconify from '../../Iconify'
import { Theme } from '@mui/material/styles'

type AssetStatsProps = {
  asset: HostedContentRoomAsset,
  sx?: SxProps<Theme>
}

export function AssetStats({ asset, sx }: AssetStatsProps) {
  const totalComments = 32
  const totalViews = 29520
  const totalShares = 74
  return (
    <Stack
      spacing={1.5}
      direction="row"
      justifyContent="flex-end"
      sx={{
        typography: 'caption',
        color: 'text.disabled',
        ...sx
      }}
    >
      <Stack direction="row" alignItems="center">
        <Iconify icon="eva:message-circle-fill" width={16} sx={{ mr: 0.5 }} />
        {fShortenNumber(totalComments)}
      </Stack>

      <Stack direction="row" alignItems="center">
        <Iconify icon="solar:eye-bold" width={16} sx={{ mr: 0.5 }} />
        {fShortenNumber(totalViews)}
      </Stack>

      <Stack direction="row" alignItems="center">
        <Iconify icon="solar:share-bold" width={16} sx={{ mr: 0.5 }} />
        {fShortenNumber(totalShares)}
      </Stack>
    </Stack>
  )
}