import { Box, Stack, Typography } from '@mui/material'
import Image from '../../../Image'
import React from 'react'
import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room'
import { useParams } from 'react-router'
import { SimpleAssetPalette } from '../SimpleAssetPalette'

type ContainedImageCardProps = {
  asset: HostedContentRoomAsset
}

export function ContainedImageCard({ asset }: ContainedImageCardProps) {
  const { shortCode } = useParams()

  return (
    <Stack p={1} spacing={2}>
      <Image
        alt={asset.name}
        src={`/api/v1/cr/${shortCode}/assets/${asset.assetId}/previews/${asset.assetPreviewId}`}
        sx={{ width: 1, borderRadius: 1 }}
      />
      <Stack px={2} pb={2} spacing={1}>
        <Typography variant='h6' noWrap >
          {asset.name}
        </Typography>

        { asset.description && (<Typography variant='body2' noWrap>
          {asset.description}
        </Typography>) }

        <SimpleAssetPalette palette={asset.palette} />
      </Stack>
    </Stack>
  )
}