import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { ChangeEvent, useRef, useState } from 'react'
import Iconify from '../Iconify'


type MassetBotInputProps = {
  onSend?: (message: string) => void
}
export function MassetBotInput({onSend} : MassetBotInputProps){
  const ref = useRef()
  const [message, setMessage] = useState("")

  // handlers
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setMessage(e.currentTarget.value)
  const handleSend = () => {
    onSend && onSend(message)
    setMessage("")
  }
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") handleSend()
  }


  const sendDisabled = !message
  return (
    <Box ref={ref}>
      <TextField
        fullWidth
        placeholder='Ask MassetBot A Question...'
        onChange={handleChange}
        value={message}
        InputProps={{
          onKeyUp: handleKeyUp,
          startAdornment: (
            <InputAdornment position='start'>
              <Iconify
                icon='eva:message-square-outline'
                sx={{
                  height: 24,
                  width: 24,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position='end'
            >
              <IconButton
                onClick={handleSend}
                color="primary"
                disabled={sendDisabled}
              >
                <Iconify
                  icon='eva:navigation-outline'
                  sx={{
                    height: 24,
                    width: 24,
                    transform: "rotateZ(90deg)",
                  }}
                />
              </IconButton>

            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}