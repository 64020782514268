import { useForm } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { FormProvider, RHFTextField } from "../../hook-form";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useState } from "react";
import { EMAIL_CHECK_MESSAGES, ProfileUserInfo, UpdateProfileError } from "../../../@types/profile";
import { ProfileContext } from "../../../contexts/ProfileContext";

type InternalFormProps = {
  firstName: string
  lastName: string
  email: string
}

type Props = {}

const getDefaults = (user: ProfileUserInfo | undefined) => ({
  firstName: user?.firstName || "",
  lastName: user?.lastName || "",
  email: user?.email || "",
})

// todo: validate that the added email is permitted for this company
export function ProfileUserInfoForm({}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false)
  const { userInfo, loadUserInfo, updateUserInfo } = useContext(ProfileContext)

  const methods = useForm<InternalFormProps>({
    defaultValues: getDefaults(userInfo)
  })
  const { handleSubmit, formState, reset, setError } = methods

  useEffect(() => {
    loadUserInfo()
  }, [])
  useEffect(() => reset(getDefaults(userInfo)), [userInfo])

  const onSubmit = async (data: InternalFormProps) => {
    setSaving(true)
    try {
      const result = await updateUserInfo(data)
      const errorCodes = (result as UpdateProfileError).errorCodes || []
      const success = errorCodes.length === 0

      if (success) {
        enqueueSnackbar((<>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="smallHighlight">Success!</Typography>
            <Typography component="div" variant="small" noWrap>Your profile was updated!</Typography>
          </Stack>
        </>))
      } else {
        enqueueSnackbar((<>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="smallHighlight">Whoops!</Typography>
            <Typography component="div" variant="small" noWrap>Clean up any errors and try again.</Typography>
          </Stack>
        </>), { variant: "error" })
        errorCodes.forEach((code) => {
          setError("email", { type: "custom", message: EMAIL_CHECK_MESSAGES[code] })
        })
      }
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <Box maxWidth={440}>
        <FormProvider methods={methods}>
          <Stack direction="column" spacing={3}>
            <RHFTextField
              name="firstName"
              label="First Name"
              variant="filled"
              size="small"
            />
            <RHFTextField
              name="lastName"
              label="Last Name"
              variant="filled"
              size="small"
            />
            <RHFTextField
              name="email"
              label="Email"
              variant="filled"
              size="small"
            />

            <Box>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                loading={saving}
                disabled={!formState.isValid}
              >
                Save
              </LoadingButton>
            </Box>
          </Stack>
        </FormProvider>
      </Box>
    </>
  )
}