import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import Iconify from '../../Iconify'
import { DEFAULT_GROUP_LIST_ITEMS_DATA, useGroupsList } from '../../../hooks/useGroups'
import { Link } from 'react-router-dom'
import React, { useMemo, useState } from 'react'
import { GroupListItem } from '../../../@types/group'
import groupBy from 'lodash/groupBy'
import { fNumber } from '../../../utils/formatNumber'
import orderBy from 'lodash/orderBy'
import { GroupDeleteButton } from './GroupDeleteButton'
import EmptyContent from '../../EmptyContent'

export function HierarchyTable() {
  const query = useGroupsList()
  const { groups, groupsById } = query.data || DEFAULT_GROUP_LIST_ITEMS_DATA
  const rootGroups = useMemo(() => toGroupItems(groups).filter(it => !it.parentGroupId), [groups])

  if (query.isError) {
    return (
      <Alert severity='error' variant='outlined' icon={<Iconify icon='eva:error-outline' />}>
        <AlertTitle>Hmmm... that's not right</AlertTitle>
        Well, this is embarrassing. It looks like something went wrong trying to load groups. Go ahead and give
        the page a refresh to fix it. If this issue continues, please reach out and let us know at
        support@getmasset.com!
      </Alert>
    )
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={'100%'} />
          {/* direct user counts */}
          <TableCell>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Iconify icon='eva:people-fill' sx={{ height: 18, width: 18 }} />
              <Typography variant='small'>Direct</Typography>
              <Tooltip title='Members assigned directly to this group'>
                <Box>
                  <Iconify icon='eva:question-mark-circle-outline' sx={{ color: 'text.deemphasized' }} />
                </Box>
              </Tooltip>
            </Stack>
          </TableCell>

          {/* indirect user counts */}
          <TableCell>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Iconify icon='eva:people-outline' sx={{ height: 18, width: 18 }} />
              <Typography variant='small'>All</Typography>
              <Tooltip title='All group members (including sub groups)'>
                <Box>
                  <Iconify icon='eva:question-mark-circle-outline' sx={{ color: 'text.deemphasized' }} />
                </Box>
              </Tooltip>
            </Stack>
          </TableCell>

          {/* actions */}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rootGroups.map(group => (
            <DepthTableRow key={group.groupId} group={group} groupsById={groupsById} depth={1} />))
        }

        <TableCell colSpan={3}>
          {
            groups.length === 0 && (
              <EmptyContent
                title='No groups'
                description="Looks like you haven't created any groups. Click the Add button above to get started!"
              />
            )
          }
        </TableCell>

      </TableBody>
    </Table>

  )
}

function DepthTableRow({ group, groupsById, depth }: {
  group: HierarchyGroupItem,
  groupsById: Dictionary<GroupListItem>,
  depth: number
}) {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const handleCollapse = () => setCollapsed(!collapsed)
  return (
    <>
      <TableRow>
        <TableCell>
          <Stack direction='row' sx={{ pl: (depth - 1) * 3 }} spacing={.5} alignItems='center'>
            <Box sx={{ height: 16, width: 16 }}>
              {!!group.children.length && (
                <Iconify
                  icon='eva:chevron-right-fill'
                  sx={{
                    height: 16,
                    width: 16,
                    transform: collapsed ? '' : 'rotate(90deg)',
                    transition: 'transform 150ms ease-in-out',
                    cursor: 'pointer',
                  }}
                  onClick={handleCollapse}
                />
              )}
            </Box>
            <Typography variant='smallHighlight'>{group.name}</Typography>
          </Stack>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='small'>{fNumber(group.numSelfMembers)}</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='small'>{
            group.children.length === 0 ? '--' : fNumber(group.numInclusiveMembers)
          }</Typography>
        </TableCell>
        <TableCell>
          <Stack
            spacing={1}
            direction='row'
            justifyContent='end'
          >
            <Button
              component={Link}
              to={`/admin/groups/${group.groupId}`}
              size='small'
              variant='text'
              color='mint'
              startIcon={
                <Iconify color='text.mint' icon='eva:edit-outline' />
              }
              sx={{
                '& .MuiButton-startIcon': { marginRight: '4px' },
              }}
            >Edit</Button>
            <GroupDeleteButton group={group} children={group.children} />
          </Stack>
        </TableCell>
      </TableRow>

      {
        !collapsed && group.children.map(child => (
            <DepthTableRow key={child.groupId} group={child} groupsById={groupsById} depth={depth + 1} />
          )
        )
      }
    </>
  )
}

interface Dictionary<T> {
  [key: string]: T;
}

interface HierarchyGroupItem extends GroupListItem {
  children: HierarchyGroupItem[],
}

function toGroupItems(groups: GroupListItem[]): HierarchyGroupItem[] {
  const byParentId = groupBy(groups, 'parentGroupId')
  return groups.map(it => toGroupItem(it, byParentId))
}

function toGroupItem(group: GroupListItem, byParentId: Dictionary<GroupListItem[]>): HierarchyGroupItem {
  const children = (byParentId[group.groupId] || []).map(it => toGroupItem(it, byParentId))
  const sorted = orderBy(children, 'name', 'asc')
  return { ...group, children: sorted }
}