import { CategoryValue } from '../../../@types/category'
import { styled } from '@mui/material/styles'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import CategoryValueReassignButton from './CategoryValueReassignButton'
import CategoryValueDeleteButton from './CategoryValueDeleteButton'
import CategoryValueRenameButton from './CategoryValueRenameButton'

const StyleTagBox = styled(Box)(({ theme }) => {
  const isLight = theme.palette.mode === "light";
  const color = theme.palette.text.secondary
  const backgroundColor = isLight ? theme.palette.bg.warmGrey0 : theme.palette.background.neutral
  const borderColor = theme.palette.grey[500_12];
  return {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1, 1, 2),
    border: `1px solid ${borderColor}`,
    color,
    backgroundColor,
    display: "inline-block"
  };
});

export default function EditableCategoryValue({ categoryValue }: { categoryValue: CategoryValue }) {
  return (
    <StyleTagBox
      sx={{ mr: 2, mb: 2 }}
    >
      <Stack direction="row" spacing={3} alignItems="center">
        <Stack direction="column">
          <Typography variant="standardHighlight">{categoryValue.value}</Typography>
          <Typography variant="tiny" color="text.deemphasized">Used {categoryValue.usage} times</Typography>
        </Stack>
        <Stack direction="row" spacing={0}>
          <CategoryValueRenameButton categoryValue={categoryValue} />
          <CategoryValueReassignButton categoryValue={categoryValue} />
          <CategoryValueDeleteButton categoryValue={categoryValue} />
        </Stack>
      </Stack>
    </StyleTagBox>
  );
}