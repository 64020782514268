import AssetLabelsCmp from "../../asset-labels/AssetLabels";
import AssetPalettesCmp from "../../asset-palette/AssetPalettes";
import React from "react";
import { Asset } from "../../../@types/asset";
import { Stack } from "@mui/material";

type AssetDetailAITabProps = {
  asset: Asset
}

export function AssetDetailAITab({ asset } : AssetDetailAITabProps){
  return (
    <Stack spacing={1}>
      <AssetLabelsCmp asset={asset} />
      <AssetPalettesCmp asset={asset} />
    </Stack>
  )
}