import { Box, Button, Dialog, IconButton, Stack, Typography } from "@mui/material";
import Iconify from "../../Iconify";
import React, { useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Asset } from "../../../@types/asset";
import { FormProvider, RHFUploadSingleFile } from "../../hook-form";
import CompactBlockContent from "../../upload/CompactBlockContent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { CustomFile } from "../../upload";
import { AssetContext } from "../../../contexts/AssetContext";

const PREVIEW_LIMIT_SIZE = 1024 * 1024 * 8 // 8 mb


type OverridePreviewFormValuesProps = {
  file: CustomFile;
};

export function OverridePreviewButton({ asset }: { asset: Asset }) {
  const { overrideAssetPreviews } = useContext(AssetContext)
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const methods = useForm<OverridePreviewFormValuesProps>({
    resolver: yupResolver(Yup.object().shape({
      file: Yup.mixed<any>().nullable().required()
    })),
    defaultValues: { file: null as unknown as CustomFile }
  });
  const { setValue, formState, handleSubmit, getValues, reset } = methods

  const handleDrop = (files: File[]) => {
    const file = files[0]
    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    setValue("file", newFile, { shouldValidate: true })
  }

  const onOverrideClick = (data: OverridePreviewFormValuesProps) => {
    setLoading(true)
    overrideAssetPreviews(asset.assetId, data.file)
      .then(() => setOpen(false))
      .finally(() => setLoading(false))
  }

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          reset()
          setOpen(true)
        }}
        startIcon={<Iconify icon="eva:image-outline" />}
      >
        Override Preview
      </Button>

      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7} >
          <Box sx={{ position: "absolute", right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>

          <Stack spacing={4}>
            <Typography variant="h2">
              Override your current preview?
            </Typography>

            <Typography variant="standard" color="text.secondary" component="div">
              Overriding your preview allows you to specify a custom image to show your
              users when viewed inside of Masset. A custom preview will not affect the original asset.
            </Typography>

            <FormProvider methods={methods}>
              <RHFUploadSingleFile
                name="file"
                accept={{
                  "image/png": [],
                  "image/gif": [],
                  "image/jpeg": []
                }}
                maxSize={PREVIEW_LIMIT_SIZE}
                maxFiles={1}
                onDrop={handleDrop}
                customBlockContent={<CompactBlockContent />}
                previewBackgroundType="contain"
              />
            </FormProvider>
          </Stack>

          <Stack spacing={2} mt={4}>
            <LoadingButton
              loading={loading}
              fullWidth={true}
              variant="contained"
              color="primary"
              size="large"
              disabled={!formState.isValid}
              onClick={handleSubmit(onOverrideClick)}
            >
              Override Preview
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="outlined"
              size="large"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </Box>
  );
}


