import { useFormContext } from 'react-hook-form'
import { UPLOAD_LIMIT_SIZE } from '../asset-upload/AssetFileUpload'
import { CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNewAssetRevisionMutation } from '../../hooks/useAsset'
import { RHFCheckbox, RHFUploadSingleFile } from '../hook-form'

export function NewVersionForm() {
  const { setValue } = useFormContext()
  const { isLoading } = useNewAssetRevisionMutation()

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length == 1) {
      setValue("file", acceptedFiles[0], { shouldValidate: true })
    }
  }

  return (
    <Stack direction='column' spacing={3}>
      <RHFUploadSingleFile
        name="file"
        maxSize={UPLOAD_LIMIT_SIZE}
        onDrop={handleDrop}
        disabled={isLoading}
        customBlockContent={isLoading ? (
          <Stack direction='row' alignItems='center' justifyContent='center' sx={{ flex: 1 }}>
            <CircularProgress size={64} />
          </Stack>
        ) : undefined}
      />

      <Stack direction="column" spacing={0}>
        <Typography variant="smallHighlight">Notification settings</Typography>
        <RHFCheckbox name="notifyDownloaders" label={<Typography variant="small">Notify users that have <b><i>downloaded</i></b> this asset.</Typography>} />
        <RHFCheckbox name="notifySharers" label={<Typography variant="small">Notify users that have <b><i>shared</i></b> this asset.</Typography>} />
        <RHFCheckbox name="notifyViewers" label={<Typography variant="small">Notify users that have previously <b><i>browsed</i></b> to this asset.</Typography>}/>
      </Stack>
    </Stack>
  )
}