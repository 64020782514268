import React, { Fragment } from 'react'
import Page from '../../components/Page'
import { Divider, Stack, Typography } from '@mui/material'
import LoadingScreen from '../../components/LoadingScreen'
import EditableAssetCategory from '../../components/admin/category/EditableAssetCategory'
import NewCategoryDialog from '../asset/NewCategoryDialog'
import { useCategories } from '../../hooks/useCategories'

export default function AdminCategories(){
  const { data: categories } = useCategories()

  if(!categories){
    return <LoadingScreen/>
  }

  return (
    <Page title="Category Management">
      <Stack direction="row" alignItems="start" justifyContent="space-between" mb={6}>
        <Typography variant="h2" >Category Management</Typography>
        <NewCategoryDialog showAutoAssignOption={false} />
      </Stack>

      { categories.map((it, idx) => {
        return (
          <Fragment key={it.categoryId}>
            {!!idx && <Divider sx={{ my: 3 }} /> }
            <EditableAssetCategory category={it} />
          </Fragment>
        )
      }) }
    </Page>
  )
}