import { DomainClaim } from '../../@types/company'
import { Box, Paper, Stack, Tooltip, Typography } from '@mui/material'
import Iconify from '../Iconify'
import React from 'react'

export function DomainClaims({ claims }: { claims: DomainClaim[] }) {
  return (
    <Paper sx={{p: 2}} >
      <Typography variant="h4" >Domains</Typography>
      <Typography variant='caption' color="text.deemphasized" paragraph mt={1}>
        Claiming ownership of domains must be done manually. Please reach out to support@getmasset.com if you
        wish to make update domains that your company owns.
      </Typography>
      <Stack spacing={1} mt={4}>
        {
          claims.map(it => {
            return (
              <DomainClaimCmp key={it.domainClaimId} claim={it} />
            )
          })
        }
      </Stack>
    </Paper>
  )
}


function DomainClaimCmp({ claim }: { claim: DomainClaim }) {
  return (
    <Stack direction='row' alignItems="center" justifyContent='space-between'>
      <Typography variant="standard">{claim.domain}</Typography>
      <Tooltip title='This domain has been verified by Masset'>
        <Box>
          <Iconify icon='eva:checkmark-circle-2-fill' color='text.mint' sx={{ height: 24, width: 24 }} />
        </Box>
      </Tooltip>
    </Stack>
  )
}