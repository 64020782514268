import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import Image from '../../../Image'
import { alpha, useTheme } from '@mui/material/styles'
import React from 'react'
import { useParams } from 'react-router'
import { AssetStats } from '../AssetStats'

type OverlaidImageCardProps = {
  asset: HostedContentRoomAsset
}

export function OverlaidImageCard({ asset }: OverlaidImageCardProps) {
  const { shortCode } = useParams()
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >

      <Avatar
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          background: theme.palette.primary.light,
          zIndex: 10
        }}
      />

      <Stack
        spacing={1}
        sx={{
          p: 3,
          width: 1,
          bottom: 0,
          zIndex: 9,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
        }}
      >

        <Typography variant='h5' noWrap>
          {asset.name}
        </Typography>

        {asset.description && (<Typography variant='body2' noWrap>
          {asset.description}
        </Typography>)}

        <Box pt={2}>
          <AssetStats
            asset={asset}
            sx={{
              // color: alpha(theme.palette.text.primary, .65)
              color: alpha(theme.palette.primary.contrastText, .65)
            }}
          />
        </Box>
      </Stack>

      <Image
        alt={asset.name}
        src={`/api/v1/cr/${shortCode}/assets/${asset.assetId}/previews/${asset.assetPreviewId}`}
        overlay={alpha(theme.palette.grey[900], 0.70)}
        sx={{width: 1}}
      />
    </Box>
  )
}