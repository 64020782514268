import { Asset } from "../../../../@types/asset";
import { AnalyticsInterval, TimeRange } from '../../../../@types/analytics/analytics-common'
import BucketedViews from "./view/BucketedViews";
import TopViewers from "./view/TopViewers";
import React from "react";
import { Grid } from "@mui/material";
import TotalViews from "./view/TotalViews";
import ViewRank from "./view/ViewRank";

export default function ViewMetricsAssetDetailsLayout({ asset, range }: { asset: Asset, range: TimeRange }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <TotalViews asset={asset} range={range} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <ViewRank asset={asset} range={range} />
      </Grid>
      <Grid item xs={12}>
        <BucketedViews asset={asset} range={range} />
      </Grid>
      <Grid item xs={12}>
        <TopViewers asset={asset} range={range} />
      </Grid>
    </Grid>
  );
}
