import { Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useContentRooms } from '../../hooks/useContentRooms'
import { fDate } from '../../utils/formatTime'
import { ContentRoomDeleteButton } from './ContentRoomDeleteButton'
import { Link } from 'react-router-dom'
import Iconify from '../Iconify'
import React from 'react'

export function ContentRoomTable() {
  const { data } = useContentRooms()
  // todo: skeleton loading state
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Url</TableCell>
          <TableCell>Expires</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.rooms.map(it => (
            <TableRow key={it.contentRoomId}>
              <TableCell>{it.name}</TableCell>
              <TableCell>{`https://public.app.getmasset.com/cr/${it.shortCode}`}</TableCell>
              <TableCell>{it.expiresAt ? fDate(it.expiresAt) : 'Never'}</TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <ContentRoomDeleteButton room={it} />
                  <Button
                    component={Link}
                    to={`/content-rooms/${it.contentRoomId}`}
                    size='small'
                    variant='text'
                    color='mint'
                    startIcon={
                      <Iconify color='text.mint' icon='eva:edit-outline' />
                    }
                    sx={{
                      '& .MuiButton-startIcon': { marginRight: '4px' },
                    }}
                  >Edit</Button>
                </Stack>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  )
}